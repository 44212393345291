@import 'variables';

.index-promotion {
  margin-top: 10px;
  margin-bottom: 60px;
}

.index-promotion__heading {
  display: block;
  font-family: Leto Sans, sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  color: @gray--darken;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.index-promotion__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.slider-number {
  position: absolute;
  top: 0;
  left: -75px;
  width: 50px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: @gray;
  text-align: center;
  border-top: 1px solid @gray--light;

  @media screen and (max-width: 1200px) {
    left: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.index-promotion__slider {
  width: ~"calc(100% - 380px - 30px)";

  .slick-list {
    border-radius: 9px;
  }

  a {
    display: block !important;

    img {
      width: 100%;

    }
  }

  @media screen and (max-width:1100px) {
    width: 100%;
  }
}

.index-promotion__slider {

  .slick-prev {
    top: unset;
    left: unset;
    right: 78px;
    bottom: 25px;
    transform: unset;
    -webkit-transform: unset;
  }

  .slick-next {
    top: unset;
    right: 25px;
    bottom: 25px;
    transform: unset;
    -webkit-transform: unset;
  }
}

.index-promotion__banner {
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;

  @media screen and (max-width:1100px) {
    width: 100%;
    flex-direction: row;
    margin-left: 0;
    margin-top: 30px;
  }

  @media screen and (max-width:768px) {
    flex-direction: column;
    margin-top: 0;
  }

  a {
    display: block;
    width: 100%;
    height: ~"calc(100% / 2 - 15px)";
    max-height: 180px;
    border-radius: 9px;
    padding: 20px 25px;

    span {
      font-family: "Leto Sans", sans-serif, Arial, Helvetica;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 1.3px;
      color: @white;
      text-transform: uppercase;
    }

    @media screen and (max-width:1100px) {
      width: ~"calc(100% / 2 - 15px)";
      height: 175px;
    }

    @media screen and (max-width:768px) {
      width: 100%;
      height: 175px;
      margin-top: 10px;
    }
  }
}