@import 'variables';

.index-collections {
  /*background: linear-gradient(to bottom, lighten(@gray--lighten, 0%), @gray--lighten);*/
  background: @gray--lighten;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.index-collections__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.index-collections__heading {
  display: block;
  font-family: "Leto Sans", sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  color: @gray--darken;
  text-transform: uppercase;
  padding-bottom: 25px;

  &:hover {
    color: @gray--dark;
    transition: all .3s;
  }
}

.collections-menu__list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.collections-menu__item {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.collections-menu__link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: @color-main;
  white-space: nowrap;
  padding: 16px 32px;
  border-radius: 27px;
  background-color: @white;

  &:hover {
    color: @color-main--dark;
    box-shadow: 0 4px 8px rgba(42,42,243,.16);
    transition: all .3s;
  }
}

.index-collections__search {}

.collections-search__form {
  position: relative;
  margin: 50px 0 70px;
}

.collections-search__input {
  display: block;
  position: relative;
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -.22px;
  line-height: normal;
  color: @gray--darken;
  padding: 30px 180px 30px 20px;
  background-color: @white;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(42,42,243,.16) !important;
  outline: none;

  &::placeholder {
    color: @gray;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 68px 30px 20px;
  }
}

.collections-search__button {
  z-index: 5;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 160px;
  height: 48px;

  border-radius: 6px;
  border: 1px solid @color-main;
  background: linear-gradient(to top, #5151FF, @color-main);

  &:hover {
    border: 1px solid;
    border-image-source: linear-gradient(to bottom, spin(#5151FF, 45), @color-main--dark);
    background: linear-gradient(to bottom, spin(#5151FF, 45), @color-main--dark);
    transition: all .3s;
  }

  &:before {
    content: "Найти";
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 46px;
    color: @white;
    text-align: center;
  }



  @media screen and (max-width: 768px) {
    width: 48px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      -webkit-mask: url(../images/svg/search.svg) no-repeat center;
      -webkit-mask-size: 20px;
      background-color: @white;
    }
  }
}

.index-collections__nav {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

#collections .slick-slide {
  margin: 0 15px 35px;
}

.index-collection {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 6px 24px rgba(42,42,243,.05);

  &:hover {
    box-shadow: 0 12px 24px rgba(42, 42, 243, .15);
    transition: all .3s;

    .index-collection__title {
      color: @gray--dark;
    }

    .index-collection__quantity {
      color: @color-main--dark;
    }
  }

  @media screen and (max-width: 1200px) {
    width: ~"calc(100% / 2 - 90px)";
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.index-collection__link {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.index-collection__books {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 230px;
  padding: 60px 30px 0;
  border-radius: 8px 8px 0 0;

  @media screen and (max-width: 768px) {
    height: 200px;
    padding: 20px 15px 0;
  }
}

.index-collection__book {
  width: ~"calc(100% / 4 - 20px)";

  @media screen and (max-width: 768px) {
    width: ~"calc(100% / 4 - 10px)";
  }
}

.index-collection__footer {
  display: flex;
  flex-direction: column;
  height: 165px;
  padding: 30px;
  background-color: @white;
  border-radius: 0 0 8px 8px;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}

.index-collection__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  color: @gray--darken;
  text-align: left;
}

.index-collection__attributes {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: auto;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.index-collection__quantity {
  font-size: 16px;
  font-weight: 600;
  color: @color-main;
}

.index-collection__tag {
  font-size: 12px;
  font-weight: 600;
  color: #898D91;
  text-transform: uppercase;
}