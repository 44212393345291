@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,600,700,700i&subset=cyrillic&display=swap');
@font-face {
  font-family: 'Leto Sans';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/letosans-bold-webfont.svg') format('svg'), url('../fonts/letosans-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Leto Sans';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/letosans-thin-webfont.svg') format('svg'), url('../fonts/letosans-thin-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Leto Sans';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/letosans-webfont.svg') format('svg'), url('../fonts/letosans-webfont.woff') format('woff');
}
/*@font-size: 18px;

.set-font(@font-size, @line-height: @font-size) {
	font-size: @font-size;
	line-height: @line-height;
}

.set-margin(@margin-top: @font-size, @margin-bottom: @font-size) {
	margin-top: @margin-top;
	margin-bottom: @margin-bottom;
}*/
.index-promotion {
  margin-top: 10px;
  margin-bottom: 60px;
}
.index-promotion__heading {
  display: block;
  font-family: Leto Sans, sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  color: #232325;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.index-promotion__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.slider-number {
  position: absolute;
  top: 0;
  left: -75px;
  width: 50px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #B4BAC1;
  text-align: center;
  border-top: 1px solid #E5E5E5;
}
@media screen and (max-width: 1200px) {
  .slider-number {
    left: 0;
  }
}
@media screen and (max-width: 768px) {
  .slider-number {
    display: none;
  }
}
.index-promotion__slider {
  width: calc(100% - 380px - 30px);
}
.index-promotion__slider .slick-list {
  border-radius: 9px;
}
.index-promotion__slider a {
  display: block !important;
}
.index-promotion__slider a img {
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .index-promotion__slider {
    width: 100%;
  }
}
.index-promotion__slider .slick-prev {
  top: unset;
  left: unset;
  right: 78px;
  bottom: 25px;
  transform: unset;
  -webkit-transform: unset;
}
.index-promotion__slider .slick-next {
  top: unset;
  right: 25px;
  bottom: 25px;
  transform: unset;
  -webkit-transform: unset;
}
.index-promotion__banner {
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}
@media screen and (max-width: 1100px) {
  .index-promotion__banner {
    width: 100%;
    flex-direction: row;
    margin-left: 0;
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .index-promotion__banner {
    flex-direction: column;
    margin-top: 0;
  }
}
.index-promotion__banner a {
  display: block;
  width: 100%;
  height: calc(100% / 2 - 15px);
  max-height: 180px;
  border-radius: 9px;
  padding: 20px 25px;
}
.index-promotion__banner a span {
  font-family: "Leto Sans", sans-serif, Arial, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.3px;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media screen and (max-width: 1100px) {
  .index-promotion__banner a {
    width: calc(100% / 2 - 15px);
    height: 175px;
  }
}
@media screen and (max-width: 768px) {
  .index-promotion__banner a {
    width: 100%;
    height: 175px;
    margin-top: 10px;
  }
}
@keyframes scaleInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.15, 1.15);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes scaleInOut {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.15, 1.15);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.index-sources {
  margin-bottom: 80px;
}
.index-sources__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 0 30px;
  margin: 0;
  list-style: none;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.index-sources__item {
  width: calc(100% / 4 - 20px);
  margin-top: 15px;
}
@media screen and (max-width: 1100px) {
  .index-sources__item {
    width: calc(100% / 2 - 20px);
  }
}
@media screen and (max-width: 768px) {
  .index-sources__item {
    width: 100%;
  }
}
.index-sources__link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.36;
  color: #232325;
}
.index-sources__link:hover {
  color: #898D91;
  transition: all .3s;
}
.index-sources__link:hover .index-sources__image {
  animation: scaleInOut 1s;
  -webkit-animation: scaleInOut 1s;
}
.index-sources__image {
  margin-right: 20px;
}
.index-agenda {
  margin-bottom: 60px;
}
.index-collections {
  /*background: linear-gradient(to bottom, lighten(@gray--lighten, 0%), @gray--lighten);*/
  background: #f5f6f7;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;
}
.index-collections__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.index-collections__heading {
  display: block;
  font-family: "Leto Sans", sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  color: #232325;
  text-transform: uppercase;
  padding-bottom: 25px;
}
.index-collections__heading:hover {
  color: #898D91;
  transition: all .3s;
}
.collections-menu__list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .collections-menu__list {
    flex-wrap: wrap;
  }
}
.collections-menu__item {
  margin-right: 15px;
}
.collections-menu__item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .collections-menu__item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.collections-menu__link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
  white-space: nowrap;
  padding: 16px 32px;
  border-radius: 27px;
  background-color: #FFFFFF;
}
.collections-menu__link:hover {
  color: #7900f3;
  box-shadow: 0 4px 8px rgba(42, 42, 243, 0.16);
  transition: all .3s;
}
.collections-search__form {
  position: relative;
  margin: 50px 0 70px;
}
.collections-search__input {
  display: block;
  position: relative;
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.22px;
  line-height: normal;
  color: #232325;
  padding: 30px 180px 30px 20px;
  background-color: #FFFFFF;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(42, 42, 243, 0.16) !important;
  outline: none;
}
.collections-search__input::placeholder {
  color: #B4BAC1;
}
@media screen and (max-width: 768px) {
  .collections-search__input {
    padding: 30px 68px 30px 20px;
  }
}
.collections-search__button {
  z-index: 5;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 160px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #003DF3;
  background: linear-gradient(to top, #5151FF, #003DF3);
}
.collections-search__button:hover {
  border: 1px solid;
  border-image-source: linear-gradient(to bottom, #d351ff, #7900f3);
  background: linear-gradient(to bottom, #d351ff, #7900f3);
  transition: all .3s;
}
.collections-search__button:before {
  content: "Найти";
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 46px;
  color: #FFFFFF;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .collections-search__button {
    width: 48px;
  }
  .collections-search__button:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    -webkit-mask: url(../images/svg/search.svg) no-repeat center;
    -webkit-mask-size: 20px;
    background-color: #FFFFFF;
  }
}
.index-collections__nav {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
#collections .slick-slide {
  margin: 0 15px 35px;
}
.index-collection {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.05);
}
.index-collection:hover {
  box-shadow: 0 12px 24px rgba(42, 42, 243, 0.15);
  transition: all .3s;
}
.index-collection:hover .index-collection__title {
  color: #898D91;
}
.index-collection:hover .index-collection__quantity {
  color: #7900f3;
}
@media screen and (max-width: 1200px) {
  .index-collection {
    width: calc(100% / 2 - 90px);
  }
}
@media screen and (max-width: 768px) {
  .index-collection {
    width: 100%;
  }
}
.index-collection__link {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.index-collection__books {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 230px;
  padding: 60px 30px 0;
  border-radius: 8px 8px 0 0;
}
@media screen and (max-width: 768px) {
  .index-collection__books {
    height: 200px;
    padding: 20px 15px 0;
  }
}
.index-collection__book {
  width: calc(100% / 4 - 20px);
}
@media screen and (max-width: 768px) {
  .index-collection__book {
    width: calc(100% / 4 - 10px);
  }
}
.index-collection__footer {
  display: flex;
  flex-direction: column;
  height: 165px;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;
}
@media screen and (max-width: 768px) {
  .index-collection__footer {
    padding: 15px;
  }
}
.index-collection__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  color: #232325;
  text-align: left;
}
.index-collection__attributes {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: auto;
}
@media (max-width: 768px) {
  .index-collection__attributes {
    flex-wrap: wrap;
  }
}
.index-collection__quantity {
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
}
.index-collection__tag {
  font-size: 12px;
  font-weight: 600;
  color: #898D91;
  text-transform: uppercase;
}
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  cursor: default;
  position: relative;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: -0.3px;
  color: #232325;
  background-color: #f5f6f7;
}
a {
  cursor: pointer;
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  padding: 0;
}
.button--accent {
  display: block;
}
.button--secondary {
  display: block;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.hidden {
  display: none!important;
}
.layout-wrapper {
  width: 100%;
  max-width: 1400px;
  background-color: #FFFFFF;
  margin: auto;
}
.container,
.activities__inner {
  width: 100%;
  max-width: 1240px;
  margin: auto;
  padding: 0 20px;
}
@media screen and (max-width: 768px) {
  .container,
  .activities__inner {
    padding: 0 10px;
  }
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
/* BUTTONS */
.btn {
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.btn-bordered--blue {
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
  border: 1px solid #003DF3;
  border-radius: 9px;
  background-color: #FFFFFF;
  padding: 1em 2em;
}
.btn-bordered--blue:hover {
  color: #FFFFFF;
  background-color: #7900f3;
  border: 1px solid #7900f3;
  transition: all .3s;
}
.btn-filled--blue {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .25px;
  color: #ffffff;
  text-transform: uppercase;
  border: 0;
  border-radius: 4px;
  background-color: #003DF3;
  padding: 1em 2em;
}
.btn-filled--blue:hover {
  background-color: #5100f3;
  transition: all .3s;
}
.btn-filled--white {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .25px;
  color: #003DF3;
  text-transform: uppercase;
  border: 0;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 1em 2em;
  border: 1px solid #E0E0FA;
  box-shadow: 0 2px 5px rgba(0, 0, 243, 0.12);
}
.btn-filled--white:hover {
  background-color: #ffffff;
  transition: all .3s;
}
.show-more {
  position: relative;
  width: 100%;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 75px;
  color: #003DF3;
  border: 3px solid #003DF3;
  border-radius: 5px;
  margin-top: 90px;
}
.show-more:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 24px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-mask: url(../images/svg/arrow.svg) no-repeat center;
  -webkit-mask-size: 20px;
  background-color: #003DF3;
}
.show-more:after {
  content: '';
  position: absolute;
  top: 20px;
  right: 24px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-mask: url(../images/svg/arrow.svg) no-repeat center;
  -webkit-mask-size: 20px;
  background-color: #003DF3;
}
.show-more:hover {
  color: #7900f3;
  border: 3px solid #7900f3;
  transition: .2s;
}
.show-more:hover:before,
.show-more:hover:after {
  background-color: #7900f3;
}
/* COOKIE NOTIFY */
.cookie-notify {
  z-index: 10000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: #0E0E0F;
}
.cookie-notify .cookie-notify-inner {
  width: 100%;
  max-width: 1400px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.cookie-notify .cookie-notify-text {
  max-width: 800px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 1.36;
  color: #B4BAC1;
}
.cookie-notify .cookie-notify-text strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: #FFFFFF;
}
.cookie-notify .cookie-notify-text a {
  cursor: pointer;
  color: #B4BAC1;
  text-decoration: underline !important;
}
.cookie-notify .cookie-notify-text a:hover {
  color: #FFFFFF;
  transition: all .3s;
}
.cookie-notify .cookie-accept {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-mask: url(../images/svg/accept.svg) center no-repeat;
  -webkit-mask-size: 30px;
  background-color: #FFFFFF;
  margin-left: 40px;
}
.cookie-notify .cookie-accept:hover {
  background-color: #B4BAC1;
  transition: all .3s;
}
@media screen and (max-width: 768px) {
  .cookie-notify-inner {
    flex-direction: column;
  }
  .cookie-notify-inner .cookie-accept {
    margin-left: 0;
    margin-top: 20px;
  }
}
.gradient-bg {
  background: linear-gradient(to top, #F1F1FE, #F6F6FE);
}
section.index-section {
  padding-top: 20px;
  padding-bottom: 40px;
}
section.index-section a.index-section-heading {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  color: #232325;
  text-transform: uppercase;
  padding-bottom: 25px;
}
section.index-section a.index-section-heading:hover {
  color: #898D91;
  transition: all .3s;
}
section.index-section .index-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.index-section .index-section-inner {
  display: flex;
  justify-content: space-between;
}
/* INFOBAR */
.infobar {
  background-color: #232325;
}
.infobar .infobar__inner {
  display: flex;
  align-items: center;
  height: 40px;
}
.infobar span {
  font-size: 12px;
  font-weight: 700;
  color: #FFFFFF;
  margin-right: 20px;
}
.infobar a {
  font-size: 12px;
  font-weight: 300;
  color: #a8a8a8;
  margin-left: auto;
}
.infobar a:hover {
  color: #bebebe;
  transition: all .3s;
}
ul.infobar-socials {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.infobar-socials li a {
  display: block;
  width: 24px;
  height: 24px;
  background-color: #3D3A3A;
  border-radius: 6px;
}
ul.infobar-socials li a.infobar-socials-vk:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/svg/vk-icon.svg) no-repeat center;
  background-size: 16px;
}
ul.infobar-socials li a.infobar-socials-tg:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/svg/telegram-icon.svg) no-repeat center;
  background-size: 14px;
}
ul.infobar-socials li a.infobar-socials-yt:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/svg/youtube-icon.svg) no-repeat center;
  background-size: 16px;
}
ul.infobar-socials li + li {
  margin-left: 8px;
}
@media screen and (max-width: 900px) {
  .infobar span {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .infobar {
    display: none;
  }
}
/* NAVIGATION */
nav {
  z-index: 1000;
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
}
nav .nav__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
a.fixed-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  padding: 5px;
  background: #003DF3 url(../images/svg/tilde-white-icon.svg) no-repeat 50%;
  background-origin: content-box;
}
a.fixed-logo:hover {
  background-color: #7900f3;
  transition: all .3s;
}
.main-menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-menu__item {
  position: relative;
  white-space: nowrap;
}
.main-menu__item + .main-menu__item {
  margin-left: 30px;
}
@media screen and (max-width: 1200px) {
  .main-menu__item + .main-menu__item {
    margin-left: 20px;
  }
}
.main-menu__item:hover > .submenu {
  display: block;
  transition: all .3s;
}
.main-menu__item--current {
  border-bottom: 2px solid #003DF3;
}
.main-menu__link {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #232325;
  padding: 18px 0;
}
.main-menu__link:hover {
  color: #898D91;
  transition: all .3s;
}
.main-menu__link--parent:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 9px;
  height: 6px;
  -webkit-mask-image: url(../images/svg/arrow-down.svg);
  -webkit-mask-size: contain;
  background-color: #232325;
  margin-top: -1px;
  margin-left: 6px;
}
.submenu {
  display: none;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 49px;
  padding: 0;
  margin: 0;
  list-style: none;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 12px 21px rgba(0, 0, 243, 0.16);
}
.submenu__item {
  margin: 0;
  min-width: 220px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}
.submenu__item:first-child {
  border-radius: 4px 4px 0 0;
}
.submenu__item:last-child {
  border-bottom: none;
  border-radius: 0 0 4px 4px;
}
.submenu__item:hover {
  background-color: #f5f6f7;
  transition: all .3s;
}
.submenu__link {
  position: relative;
  display: block;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #232325;
  padding: 10px 15px;
}
.submenu__link--store:after {
  content: "";
  position: absolute;
  right: 15px;
  display: inline-block;
  width: 23px;
  height: 26px;
  background: url(../images/svg/store.svg) no-repeat center;
  background-size: contain;
}
button.dropdown-toggle-js {
  display: none;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: #B4BAC1;
}
.dropdown-menu {
  display: flex;
  margin-left: auto;
}
.dropdown-menu a {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #003DF3;
  margin-right: 25px;
}
.dropdown-menu a:hover {
  color: #7900f3;
  transition: all .3s;
}
.dropdown-menu button {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-left: 14px;
}
.dropdown-menu button:hover {
  opacity: .8;
  transition: all .3s;
}
.dropdown-menu button#switch-special {
  background: #232325 url(../images/svg/eye.svg) no-repeat center;
}
@media screen and (max-width: 1100px) {
  button.dropdown-toggle-js {
    display: block;
  }
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    max-width: 400px;
    height: auto;
    padding: 20px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px rgba(42, 42, 243, 0.16) !important;
  }
}
@media screen and (max-width: 768px) {
  nav {
    display: none;
  }
}
/**/
.nav-mobile {
  z-index: 99;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
}
.nav-mobile ul.mobile-socials {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 20px;
  list-style: none;
}
.nav-mobile ul.mobile-socials li {
  width: calc(100% / 3);
  height: 50px;
  border: 1px solid #E5E5E5;
  border-top: none;
}
.nav-mobile ul.mobile-socials li a {
  display: block;
  width: 100%;
  height: 100%;
}
.nav-mobile ul.mobile-socials li a.link-vk:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  -webkit-mask: url(../images/svg/vk-icon.svg) no-repeat center;
  -webkit-mask-size: 20px;
  background-color: #232325;
}
.nav-mobile ul.mobile-socials li a.link-tg:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  -webkit-mask: url(../images/svg/telegram-icon.svg) no-repeat center;
  -webkit-mask-size: 18px;
  background-color: #232325;
}
.nav-mobile ul.mobile-socials li a.link-yt:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  -webkit-mask: url(../images/svg/youtube-icon.svg) no-repeat center;
  -webkit-mask-size: 20px;
  background-color: #232325;
}
.nav-mobile ul.mobile-socials li + li {
  border-left: none;
}
.nav-mobile .search {
  padding: 0 10px 20px;
}
.nav-mobile ul.quick-links {
  padding: 0 20px 20px;
}
.nav-mobile ul.mobile-menu {
  margin: 0;
  padding: 0 20px 20px;
  list-style: none;
}
.nav-mobile ul.mobile-menu li {
  white-space: nowrap;
}
.nav-mobile ul.mobile-menu li a {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 2.4;
  letter-spacing: -0.76px;
  text-transform: uppercase;
  color: #232325;
}
.nav-mobile ul.mobile-menu li a:hover {
  color: #898D91;
  transition: all .3s;
}
/* TOP BANNER */
.top-banner {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
}
.top-banner .top-banner-inner {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background-color: #F8EE6E;
  border-radius: 4px;
  padding: 22px 70px 22px 22px;
}
.top-banner .top-banner-inner .top-banner-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 67px;
  height: 100%;
  background: url(../images/svg/close-sand.svg) 50% no-repeat;
  background-size: 19px;
}
.top-banner .top-banner-inner a.top-banner-content {
  display: block;
  font-size: 16px;
  line-height: 1.36;
  font-weight: 700;
  text-align: center;
  color: #232325;
}
.top-banner .top-banner-inner a.top-banner-content:hover {
  color: #898D91;
  transition: all .3s;
}
@media screen and (max-width: 768px) {
  .top-banner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
/* HEADER MOBILE */
.header-mobile {
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.15);
  transition: top 200ms ease-out;
}
.header-mobile a.mobile-logo {
  display: block;
  width: 50px;
  height: 50px;
  background: url(https://cdn.glitch.global/2676d2a0-76ce-465a-8aa5-922c5981e655/tilde-icon.svg?v=1652746029878) no-repeat 50%;
  background-size: 30px;
}
.header-mobile button.nav-mobile-toggle-js {
  width: 50px;
  height: 50px;
  background: url(https://cdn.glitch.global/2676d2a0-76ce-465a-8aa5-922c5981e655/hamburger.svg?v=1652746016568) no-repeat 50%;
  background-size: 20px;
}
@media screen and (min-width: 768px) {
  .header-mobile {
    display: none;
  }
}
/* HEADER DESKTOP */
.header {
  display: flex;
  padding-top: 25px;
  padding-bottom: 40px;
  background-color: #FFFFFF;
}
.header a.logo img {
  width: 195px;
}
.header .quick-links {
  margin-left: 100px;
  margin-top: 20px;
}
@media screen and (max-width: 1000px) {
  .header .quick-links {
    display: none;
  }
}
.header .search {
  max-width: 400px;
  margin-left: auto;
}
.header .random-quote {
  position: relative;
  margin-top: 20px;
}
.header .random-quote__image {
  position: absolute;
  left: -170px;
  bottom: -10px;
}
.header .random-quote__caption {
  display: inline-block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.09px;
  line-height: 1em;
  color: #232325;
  padding: 15px;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: -11px -7px 12px rgba(7, 7, 240, 0.15);
}
@media screen and (max-width: 1200px) {
  .header .random-quote {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
}
/* QUICK LINKS */
ul.quick-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.quick-links li a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.36;
  color: #003DF3;
}
ul.quick-links li a:hover {
  color: #7900f3;
  transition: all .3s;
}
a.working-hours {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  color: #B4BAC1;
}
a.working-hours:hover {
  color: #898D91;
  transition: all .3s;
}
a.working-hours:after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #f6f6f6 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 16px;
  border-radius: 6px;
  transform: rotate(180deg);
  margin-bottom: -6px;
  margin-left: 8px;
}
/* SEARCH */
.search {
  width: 100%;
}
.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.search-header a.search-switch {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.36;
  color: #232325;
}
.search-header a.search-switch:hover {
  color: #898D91;
  transition: all .3s;
}
.search-header a.search-switch:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 7px;
  height: 10px;
  background: url(../images/svg/up_down.svg) no-repeat 50%;
  margin-left: 4px;
}
.search-header a.search-full-index {
  font-size: 13px;
  font-weight: 600;
  color: #232325;
}
.search-header a.search-full-index:hover {
  color: #898D91;
  transition: all .3s;
}
.search-header a.search-full-index:hover:after {
  background: #E5E5E5 url(../images/svg/arrow-back.svg) 50% no-repeat;
}
.search-header a.search-full-index:after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: #E5E5E5 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: cover;
  border-radius: 4px;
  transform: rotate(180deg);
  margin-bottom: -3px;
  margin-left: 8px;
}
.search-form {
  position: relative;
}
.search-form input {
  height: 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.5px;
  color: #232325;
  outline: none;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background: #f5f6f7;
  box-shadow: none;
  padding: 0 48px 0 20px;
}
.search-form button.search-options-toggle-js {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background: transparent;
}
.search-form button.search-options-toggle-js:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  -webkit-mask: url(../images/svg/options.svg) no-repeat center;
  -webkit-mask-size: 18px;
  background-color: #232325;
}
.search-form button.search-options-toggle-js:hover:before {
  background-color: #898D91;
  transition: all .3s;
}
.search-form ul.search-select {
  z-index: 999;
  position: absolute;
  right: 0;
  display: none;
  width: 190px;
  height: auto;
  overflow: hidden;
  list-style: none;
  margin: 0;
  margin-top: 7px;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
.search-form ul.search-select a.advanced-search {
  font-size: 14px;
  color: #003DF3;
}
.search-form ul.search-select a.advanced-search:hover {
  color: #7900f3;
  transition: all .3s;
}
.search-form ul.search-select li {
  cursor: pointer;
  font-size: 14px;
  color: #717172;
  padding: 10px 15px;
}
.search-form ul.search-select li:hover {
  color: #232325;
  background-color: #E5E5E5;
}
.search-form ul.search-select li + li {
  border-top: 1px solid #E5E5E5;
}
/**/
/* SOURCES */
ul.sources__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 0 30px;
  margin: 0;
  list-style: none;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
li.sources__item {
  width: calc(100% / 4 - 20px);
  margin-top: 15px;
}
a.sources__link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.36;
  color: #232325;
}
a.sources__link:hover {
  color: #898D91;
  transition: all .3s;
}
a.sources__link:hover img {
  transform: scale(1.2);
  transition: all .3s;
  transition-timing-function: ease-in-out;
}
a.sources__link.sources__link--disabled {
  pointer-events: none;
  color: #cbcaca;
}
img.sources__image {
  margin-right: 20px;
}
@media screen and (max-width: 1100px) {
  li.sources__item {
    width: calc(100% / 2 - 20px);
  }
}
@media screen and (max-width: 768px) {
  li.sources__item {
    width: 100%;
  }
}
/* SUBSCRIBE */
.subscribe-widget {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.subscribe-widget__icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 13px;
  background: #003DF3 url(../images/svg/plane.svg) center no-repeat;
  margin-left: 15px;
}
.subscribe-widget__icon:hover {
  background-color: #7900f3;
  transition: all .3s;
}
.subscribe-widget__features {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.36;
  letter-spacing: -0.07px;
  color: #232325;
}
.subscribe-widget__features span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.36;
  letter-spacing: -0.34px;
  color: #9DA0A3;
}
/* INDEX NEWS */
.index_news_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}
.index-news {
  flex: 1 0 min-content;
}
.index-pro {
  flex: 0 0 280px;
  margin-left: 50px;
}
a.index-news__heading,
a.index-pro__heading {
  position: relative;
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: #232325;
  text-transform: uppercase;
  margin-bottom: 25px;
}
a.index-news__heading:hover,
a.index-pro__heading:hover {
  color: #898D91;
  transition: all .3s;
}
.index-news__entity.top-entity {
  display: flex;
  flex-wrap: wrap;
}
.top-entity__image {
  /*width: 384px;*/
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 384px;
  height: 256px;
  margin-right: 50px;
}
.top-entity__image:hover {
  filter: saturate(1.2);
  transition: all .3s;
}
.top-entity__container {
  /*width: ~"calc(100% - 384px - 50px)";*/
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: min-content;
}
.top-entity__header {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}
a.top-entity__title {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}
a.top-entity__type {
  font-size: 12px;
  font-weight: 600;
  color: #898D91;
  text-transform: uppercase;
  white-space: nowrap;
}
.top-entity__date {
  font-size: 14px;
  font-weight: 400;
  color: #B4BAC1;
  white-space: nowrap;
}
a.top-entity__type + .main-entity__date {
  margin-left: 10px;
}
a.top-entity__title {
  display: block;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  color: #232325;
  margin-bottom: 25px;
}
a.top-entity__title:hover {
  color: #898D91;
  transition: all .3s;
}
.top-entity__lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.64px;
  color: #232325;
}
.index-news__entity.top-entity + .index-news__list {
  margin-top: 50px;
}
.index-news__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.index-news__entity.main-entity {
  width: calc(100% / 3 - 30px);
}
a.main-entity__type {
  font-size: 12px;
  font-weight: 600;
  color: #898D91;
  text-transform: uppercase;
  white-space: nowrap;
}
.main-entity__date {
  font-size: 14px;
  font-weight: 400;
  color: #B4BAC1;
  white-space: nowrap;
}
a.main-entity__type + .main-entity__date {
  margin-left: 10px;
}
a.main-entity__title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.36;
  color: #232325;
  margin-bottom: 25px;
}
a.main-entity__title:hover {
  color: #898D91;
  transition: all .3s;
}
a.index-news__link {
  width: calc(100% / 3 - 30px);
  font-size: 14px;
  font-weight: 600;
  color: #003DF3;
}
a.index-news__link:hover {
  color: #7900f3;
  transition: all .3s;
}
/* INDEX PRO */
a.pro-entity__image {
  display: block;
  width: 100%;
  height: 188px;
  border-radius: 10px 10px 0 0;
  background-size: cover;
}
a.pro-entity__image:hover {
  filter: saturate(1.2);
  transition: all .3s;
}
.pro-entity__container {
  background-color: #F6F6FE;
  padding: 25px 20px 30px;
}
a.pro-entity__title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.36;
  color: #232325;
  margin-bottom: 25px;
}
a.pro-entity__title:hover {
  color: #898D91;
  transition: all .3s;
}
.pro-entity__lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.64px;
  color: #232325;
}
a.pro-entity__link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
  text-align: center;
  border: 1px solid #003DF3;
  border-radius: 9px;
  white-space: nowrap;
  padding: 12px;
  margin-top: 40px;
}
a.pro-entity__link:hover {
  color: #7900f3;
  border: 1px solid #7900f3;
  transition: all .3s;
}
@media screen and (max-width: 1100px) {
  .index-news {
    flex: 0 0 100%;
  }
  .index-pro {
    flex: 0 0 100%;
    margin-left: 0;
  }
}
/* ACTIVITIES */
.activities-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 900px) {
  .activities-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .activities-list {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.activities-item {
  border-radius: 9px;
  height: 190px;
  padding: 30px 35px;
  position: relative;
}
.activities-item__link {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.activities-item__title {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  width: min-content;
}
/* COLLECTIONS INDEX */
.books-collection {
  position: relative;
  width: 100%;
  height: 393px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
}
.books-collection a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
/* Social buttons on the right of the header */
ul.side-social {
  z-index: 8888;
  position: fixed;
  display: block;
  text-align: center;
  bottom: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.side-social li {
  width: 38px;
  height: 38px;
  padding-top: 0.6rem;
  margin-top: -1px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-right: none;
}
ul.side-social li:first-child {
  border-radius: 3px 0 0 0;
}
ul.side-social li:last-child {
  border-radius: 0 0 0 3px;
}
ul.side-social li a {
  display: inline-block;
  vertical-align: top;
}
ul.side-social li a svg {
  fill: #003DF3;
  width: 16px;
  height: 16px;
  display: block;
}
ul.side-social li a svg:hover {
  fill: #a4a4a4;
  transition: fill 0.3s;
}
@media screen and (max-width: 768px) {
  .side-social {
    display: none;
  }
}
/*--------OLD MAIN.CSS--------*/
.clear {
  clear: both;
}
.clear:after {
  content: ' ';
  display: block;
  clear: both;
}
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}
h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 50px -2px;
}
h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.35;
  color: #B4BAC1;
  margin: 0;
}
.layout {
  position: relative;
}
.page-width {
  margin: 0 auto;
  min-width: 320px;
}
@media (min-width: 1024px) {
  .page-width {
    padding: 0 24px;
    max-width: 1096px;
  }
}
.open_popup {
  cursor: pointer;
}
#special_version_controls {
  display: none;
}
.content {
  /*width: 940px;
	margin: 0 auto;
	position: relative;
	left: -87px;*/
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 60px auto 0;
}
.content .content {
  left: 0;
}
.content.index_content {
  left: 0;
}
.wide_content {
  width: 1425px;
  margin: 0 auto;
}
.content_big {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.banner_wrapper img {
  max-width: 100%;
}
.banner_wrapper .column_banner {
  width: 100%;
  height: auto;
  margin: 0;
}
/*nav {
	z-index: 9999;
	position: relative;
}*/
input,
textarea {
  padding: 1px 0 0 35px;
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.5px;
  color: #232325;
  /*border-bottom: 1px solid #d5dde3;*/
}
textarea {
  border-radius: 10px;
}
input:hover,
textarea:hover {
  /*box-shadow: 0 0 3px rgba(149, 217, 210, 0.3);
	background-color: #f1f6fa;*/
}
input:focus,
textarea:focus {
  box-shadow: 0 0 5px #95d9d2;
  background-color: #FFFFFF;
}
.error {
  color: red;
  border-color: red;
}
input.error,
textarea.error {
  box-shadow: 0 0 6px rgba(255, 0, 0, 0.8);
}
.float_block {
  margin: 0 0 32px;
  float: left;
  width: 455px;
  height: 256px;
  position: relative;
  text-decoration: none;
  color: inherit;
}
a.float_block:hover {
  opacity: 1;
}
.index_float_blocks .float_full_link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
.float_image_wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*box-shadow: inset 0 0 109px rgba(0,0,0,0.75);*/
  overflow: hidden;
  background: url(../images/preloader.png) no-repeat center;
  background-size: 99% 99%;
}
.float_image {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  /*box-shadow: inset 0 0 109px rgba(0,0,0,0.75);*/
}
.float_image:before {
  position: absolute;
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(2, 85, 121, 0.52);
}
.list_type .float_image:before {
  opacity: 0 !important;
}
.float_image,
.float_image:before {
  transition: all 1200ms ease;
}
.float_title,
.float_info {
  transition: opacity 1200ms ease;
}
.list_type .float_image_wrapper {
  height: 164px;
}
.float_block:hover .float_image {
  transform: scale(1.1);
}
.float_block:hover .float_image:before {
  opacity: 0;
}
.float_block:hover .float_title,
.float_block:hover .float_info,
.float_block:hover .float_image_wrapper {
  opacity: 0.8;
}
.list_type .float_block:hover .float_image_wrapper {
  opacity: 1;
}
.list_type .float_block:hover .float_title {
  color: #003DF3;
}
.float_title,
.float_info {
  position: absolute;
  left: 28px;
  z-index: 5;
}
.float_title {
  top: 29px;
  font-weight: bold;
  font-size: 24px;
  width: 388px;
  line-height: 1.05;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
}
.float_title_text {
  display: block;
  min-height: 29px;
  letter-spacing: 0.5px;
  color: inherit;
  text-decoration: none;
}
.float_title_text:hover {
  opacity: 1;
}
.float_type {
  margin-bottom: 7px;
  font-weight: 200;
  font-size: 14px;
  text-transform: none;
  color: #FFFFFF;
  text-decoration: none;
  display: block;
}
.list_type .float_type {
  color: #545c5f;
}
.float_info {
  bottom: 15px;
  font-size: 14px;
  -webkit-transform: translateZ(0);
  white-space: nowrap;
}
.float_date {
  display: inline-block;
}
.float_place {
  font-weight: 200;
  display: inline-block;
  white-space: pre;
}
.float_flag {
  position: absolute;
  bottom: 20px;
  margin-left: 365px;
  z-index: 5;
}
.float_flag img {
  width: 64px;
}
.wide_content .float_block:nth-child(3n+2) {
  margin: 0 30px 32px;
}
.content .float_block:nth-child(2n+2) {
  margin: 0 0 32px 30px;
}
.float_small_block {
  float: left;
  width: 190px;
  margin-right: 54px;
  text-decoration: none;
  color: inherit;
  transition: all 400ms ease;
}
.float_small_block.moved {
  transform: translateX(1400px);
}
.float_small_block.moved_back {
  transform: translateX(-1400px);
}
.float_small_block:hover .float_small_image,
.float_small_block:hover .float_small_title {
  opacity: 0.8;
}
.float_small_block a {
  display: block;
  color: inherit;
  text-decoration: none;
}
.float_small_date {
  font-size: 14px;
  font-weight: 200;
  /*white-space: pre;*/
}
.float_small_image {
  width: 131px;
  height: 75px;
  position: relative;
  margin-top: 9px;
  margin-bottom: 12px;
}
.float_small_image img {
  width: 100%;
  /*max-height: 75px;*/
}
.float_small_image_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
img.float_small_flag {
  position: absolute;
  right: -15px;
  bottom: -6px;
  width: 34px;
}
a.float_small_type {
  font-size: 14px;
  font-weight: 200;
  margin-top: 0;
  margin-left: 2px;
  color: #FFFFFF;
  line-height: 1;
  margin-bottom: 10px;
}
.actual_events_block a.float_small_type,
.more_events_wrapper a.float_small_type {
  color: #545c5f;
}
.float_small_title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin-left: 1px;
  text-transform: uppercase;
}
.mobile_version {
  display: none;
}
.desktop_version {
  display: block;
}
.desktop_inline {
  display: inline;
}
.switcher_wrapper.list_type {
  margin-bottom: 37px;
}
.list_type.wide_content {
  width: 940px;
}
.list_type .float_block {
  width: 710px;
  height: auto;
  min-height: 190px;
  margin-bottom: 32px;
  color: #232325;
  overflow: hidden;
  float: none;
  display: block;
}
.list_type .float_image_wrapper {
  width: 293px;
}
.list_type .float_flag {
  bottom: 38px;
  left: 232px;
  top: auto;
  right: auto;
}
.list_type .float_title,
.list_type .float_info {
  left: 325px;
  max-width: 380px;
}
.list_type.filters_wrapper {
  margin-top: 0;
  float: right;
  width: 213px;
  margin-bottom: -100%;
}
.list_type.filters_wrapper select,
.list_type.filters_wrapper .date_wrapper,
.list_type.filters_wrapper .ui-selectmenu-button,
.list_type.filters_wrapper .input-daterange {
  width: 100% !important;
  margin: 0 0 7px !important;
  float: none;
}
.wide_content.list_type .float_block:nth-child(3n+2) {
  margin: 0 0 32px !important;
}
.wide_content.list_type .float_block:nth-child(2n+1) {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.wide_content.list_type .float_block:nth-child(2n+2) {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.list_type .float_title {
  position: relative;
  top: 0;
}
.list_type .float_info {
  position: relative;
  top: 0;
  margin-top: 5px;
  bottom: auto;
}
.list_type .float_block:hover .float_title span {
  color: #003DF3;
}
.paginator_wrapper {
  margin-top: 75px;
  text-align: center;
}
.paginator_links {
  margin: 0 105px;
  display: inline-block;
  position: relative;
  top: -11px;
}
.paginator_links a,
.paginator_links span {
  font-size: 18px;
  color: #9ea2a5;
  width: 53px;
  height: 53px;
  display: inline-block;
  padding-top: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  line-height: 18px;
}
.paginator_links a.active {
  background-color: #f6af16;
  color: #FFFFFF;
  cursor: default;
}
.paginator_arrow {
  opacity: 0.3;
  display: inline-block;
  cursor: pointer;
  background-color: #FFFFFF;
  border: none;
  padding: 0;
  outline: none;
}
.paginator_arrow.hidden {
  visibility: hidden;
}
.paginator_links.many_pages {
  width: 480px;
}
.list_type .float_block.no_image {
  min-height: 0;
  padding-bottom: 20px;
}
.list_type .no_image .float_title,
.list_type .no_image .float_info {
  max-width: none;
  left: 66px;
  width: 100%;
}
.float_block.no_image * {
  color: #232325 !important;
}
#footer {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  width: 100%;
  position: relative;
  font-size: 12px;
  line-height: 24px;
  font-weight: 200;
  color: #a2a4a4;
  background-color: #0E0E0F;
  margin-top: 100px;
  padding-top: 14px;
}
.footer_list_wrapper {
  margin-top: 42px;
  margin-left: 30px;
}
.footer_list_wrapper:after {
  clear: both;
}
.footer_list {
  float: left;
  list-style-type: none;
  font-size: 14px;
  line-height: 30px;
  color: #a2a4a4;
}
/*.menu, #footer_menu {
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	color: @gray--darken;
	list-style-type: none;
	margin: 0;
	padding: 0;
	float: left;
	margin-bottom: -1px;
	font-size: 14px;
}*/
/*#footer_menu li {
	font-family: @font-family--secondary;
	color: @white;
	line-height: 36px;
}*/
#footer_list li {
  color: #a2a4a4;
  line-height: 30px;
  text-transform: none;
}
ul.footer_list {
  padding-left: 40px;
}
ul.footer_list:first-child {
  padding-right: 60px;
}
.footer_social_wrapper {
  margin-left: 65px;
  float: left;
}
.footer_social_wrapper .footer_list {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  color: #a2a4a4;
  font-size: 12px;
  line-height: 28px;
  font-weight: 500;
}
.footer_social_header {
  color: #FFFFFF;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.footer_social_wrapper .rounded_btn {
  margin: 0 12px 0 0;
}
.vk_link:before {
  content: '';
  width: 12px;
  height: 12px;
  background: url(../images/svg/fb-icon.svg) no-repeat center;
  background-size: 14px;
}
/*.footer_counter {
	float: right;
}*/
/*.menu li {
	float: left;
	margin-right: 26px;
	padding: 18px 0 18px;
	line-height: 16px;
	height: 52px;
}
#fixed_menu li {
	height: 51px;
}
.menu li a, #footer_menu li a, .footer_list li a {
	color: inherit;
	text-decoration: none;
}
.menu li a {
	text-transform: uppercase;
	letter-spacing: 0.7px;
}
.menu li.active {
	border-bottom: 1px solid @color-main;
	/*color: #4ec0b4;
}*/
.header_right,
.footer_right {
  float: right;
  text-align: right;
  padding: 12px 0 0;
  font-size: 16px;
  line-height: 16px;
}
.header_right a {
  padding-left: 30px;
  position: relative;
  display: inline-block;
}
/*.header_right a:after {
	content: ' ';
	display: block;
	background: url(../images/become_member.png);
	width: 23px;
	height: 24px;
	position: absolute;
	right: 0;
	top: -4px;
}*/
/*#fixed_menu .header_right {
	padding: 18px 0 13px;
	line-height: 16px;
}*/
a.auth_action {
  display: inline-block;
  color: #545c5f;
  text-decoration: none;
  margin-left: 20px;
}
.footer_right a.auth_action {
  color: #FFFFFF;
  position: relative;
  top: 2px;
  line-height: 16px;
}
.auth_action img {
  position: relative;
  top: 3px;
  right: 3px;
}
a.become_link {
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  line-height: 2.14;
}
a.enter_link {
  color: #232325;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  line-height: 2.14;
}
#footer a.become_link {
  color: #FFFFFF;
  background-color: #003DF3;
  padding: 2px 9px;
  font-size: 14px;
}
.switch_language {
  position: absolute;
  top: 18px;
  right: 21px;
}
.footer_columns {
  margin-top: 40px;
}
.footer_column {
  float: left;
  max-width: 33%;
}
.footer_column_left {
  float: left;
  max-width: 60%;
}
.footer_left {
  margin-right: 100px;
}
.footer_column i {
  font-size: 12px;
  opacity: 0.5;
  line-height: 1.5;
  display: inline-block;
}
.footer_column a {
  text-decoration: none;
  /*border-bottom: 1px solid #1b6d85;*/
  line-height: 1.4;
}
.work_time a,
a.work_time {
  color: #FFFFFF;
  display: inline-block;
  border-bottom: 1px solid #FFFFFF;
}
.work_time {
  padding-left: 0;
  display: inline-block;
}
.footer_left .work_time,
small.work_time {
  opacity: 0.65;
}
.footer_left a.work_time:hover {
  opacity: 0.5;
}
.work_time small {
  font-size: 12px;
  margin-left: 10px;
}
small.work_time {
  font-size: 12px;
  margin-top: 5px;
}
.work_time p {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 0;
}
.footer_header {
  color: #FFFFFF;
  font-weight: bold;
}
.footer_copy {
  margin-top: 40px;
  border-top: 1px solid #202021;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 2;
  text-align: left;
  color: rgba(107, 114, 116, 0.3);
  padding: 23px 0 45px;
}
.footer_copy_text {
  max-width: 800px;
  float: left;
}
.footer_right {
  padding-top: 10px;
}
.footer_right img {
  margin-left: 7px;
}
.footer_banner a {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.4;
  color: #FFFFFF;
  text-decoration: none;
  width: 250px;
  float: right;
  margin-top: -0.3px;
}
.footer_year {
  display: block;
  color: #6b7274;
  font-size: 12px;
  float: right;
}
.footer_banner img {
  float: left;
  margin-right: 10px;
  width: 61px;
}
#mobile_menu_btn {
  display: none;
}
#fixed_menu.active {
  transform: translateY(0px);
}
#fixed_menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8000;
  width: 100%;
  height: 51px;
  background-color: #FFFFFF;
  transform: translateY(-200px);
  transition: all 400ms ease;
  /*border-bottom: 1px solid #d5dde3;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
/*#fixed_menu:before {
	content: ' ';
	position: absolute;
	left: 0;
	top: 0;
	width: 72px;
	background: url(../images/svg/breve_icon.svg) no-repeat center;
	display: block;
	height: 100%;
}*/
.test_logo {
  float: left;
  width: 51px;
  height: 51px;
  background: #0E0E0F;
}
.fixed_logo {
  float: left;
  padding: 5px;
  margin-right: 15px;
  background: #003DF3 url(../images/svg/tilde-white-icon.svg) no-repeat center;
  background-origin: content-box;
  width: 51px;
  height: 51px;
}
/*.fixed_logo svg > path {
	fill: red !important;
}*/
.footer_logo {
  width: 40px;
  margin-right: 30px;
  float: left;
}
.footer_logo path {
  fill: white !important;
}
#fixed_menu a.auth_action {
  margin-left: 18px;
}
.fixed_menu_wrapper {
  position: relative;
  z-index: 10000;
  background-color: #FFFFFF;
  height: 50px;
}
.inner_menu {
  width: 100%;
  height: 51px;
  background-color: #f5f6f7;
  transform: translateY(-51px);
  transition: all 400ms ease;
  z-index: 5;
  position: absolute;
}
.inner_menu.active {
  transform: translateY(0px);
}
.inner_menu_title {
  font-weight: bold;
  font-size: 16px;
  padding-top: 18px;
  float: left;
  line-height: 18px;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.inner_menu_share {
  float: right;
  margin-top: 14px;
}
.inner_share_btn.vk_btn {
  background: #507299 url(../images/svg/vk-icon.svg) no-repeat center;
  background-size: 12px;
}
.inner_share_btn.fb_btn {
  background: #3b5998 url(../images/svg/fb-icon.svg) no-repeat center;
  background-size: 12px 12px;
}
.inner_share_btn.tw_btn {
  background: #00acee url(../images/svg/tw-icon.svg) no-repeat center;
  background-size: 12px;
}
.inner_share_btn {
  float: left;
  margin: 0 3px;
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background: #003DF3;
}
.inner_menu_share span {
  float: left;
  padding-top: 4px;
  padding-right: 6px;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 200;
}
.inner_menu_share span b {
  text-transform: uppercase;
}
#fixed_menu.with_inner {
  height: 100px;
  border: none;
}
.split_line {
  float: left;
  width: 1px;
  height: 85px;
  background: #dfe4e7;
  margin: -8px 16px 0 23px;
}
.logo_95 {
  float: left;
  width: 128px;
  height: 66px;
  background: url(../images/logo_95@2x.png) no-repeat;
  background-size: cover;
}
.search_wrapper {
  z-index: 10;
  float: right;
  /*margin-left: 30px;*/
  width: 355px;
  position: relative;
}
.search_wrapper img {
  position: absolute;
  width: 12px;
  top: 19px;
  right: 25px;
}
.search_wrapper input {
  width: 100%;
  height: 47px;
  font-size: 14px;
  font-weight: 200;
  color: #000;
  /*color: rgba(66, 66, 66, 0.3);
	padding-left: 25px;
	line-height: 2.14;
	padding-right: 35px;*/
  padding: 5px 25px 0;
  border-radius: 23.5px;
  background-color: #FFFFFF;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #474f5c;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #474f5c;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #474f5c;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #474f5c;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0.3;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.3;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.3;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0.3;
}
/*.quick_links_wrapper {
	float: left;
	margin-top: -4px;
	margin-left: 76px;
}*/
/*.blue_quick_links {
	float: left;
	margin-top: -4px;
	margin-left: 80px;
}*/
/*.blue_quick_links a {
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	line-height: 30px;
}*/
/*.quick_links {
	float: left;
	margin-top: -2px;
	margin-left: 55px;
}*/
/*.quick_links {
	margin-top: 20px;
}
/*.quick_links a {
	color: #424242;
	text-decoration: none;
	font-size: 14px;
	line-height: 30px;
}
.quick_links small {
	font-family: "Open Sans";
	color: #2c2c2c;
	opacity: 0.3;
	font-size: 10px;
	position: relative;
	top: -2px;
	line-height: 1;
}*/
.wisemen_block {
  position: absolute;
  top: 15px;
  right: 0;
  width: 570px;
}
/*.quick_links a.disabled {
	opacity: 0.3 !important;
	cursor: default;
	border: none !important;
	text-decoration: none;
}*/
.work_hours_block {
  display: none;
  z-index: 100;
  position: absolute;
  padding: 11px 19px;
  background-color: #FFFFFF;
  width: 321px;
  left: 293px;
  top: 165px;
  border: 1px solid #E5E5E5;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
.work_hours_border {
  margin: 9px 0;
  padding: 8px 22px 8px 0;
  border-top: 1px solid #e6ecf1;
  border-bottom: 1px solid #e6ecf1;
}
.work_hours_row.work_hours_border:nth-child(3) {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}
/*.random_quote_block {
	position: relative;
	float: right;
	margin-top: 70px;
	margin-left: 25px;
}*/
.random_quote_block span {
  position: absolute;
  font-size: 29px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.01px;
  top: 110px;
  left: 175px;
}
.index_float_blocks {
  margin-top: 55px;
  position: relative;
  color: #FFFFFF;
  display: none;
}
.index_events_wrapper {
  width: 100%;
  background: url(../images/grad_back.png) no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  margin-top: 3px;
}
.inner_wrapper {
  width: 99999px;
  display: none;
  transition: all 400ms ease;
}
.inner_wrapper.active {
  display: block;
}
.blocks_show_wrapper {
  width: 1185px;
  overflow: hidden;
  color: #FFFFFF;
  float: left;
  margin-top: 13px;
}
.blocks_show_controls {
  float: right;
  font-weight: 200;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.blocks_show_switcher {
  display: inline-block;
  padding: 7px 0 3px;
  margin-left: 10px;
  background: url(../images/border_dotted.png) left bottom repeat-x;
  cursor: pointer;
  margin-right: 10px;
}
.blocks_show_switcher:hover {
  opacity: 0.8;
}
.blocks_show_switcher.active {
  margin: 0;
  border: none;
  background: #f6af16;
  cursor: default;
  padding: 7px 10px 3px;
  position: relative;
  right: 0;
  opacity: 1;
}
.index_other_events {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  width: 213px;
  height: 48px;
  border: 1px solid #FFFFFF;
  border-radius: 100px;
  text-align: center;
  padding-top: 16px;
  line-height: 14px;
  margin-top: 36px;
}
.index_other_events:hover {
  opacity: 1;
  background-color: #FFFFFF;
  color: #4ec0b4;
}
.blocks_show_arrows {
  width: 18px;
  margin: 40px auto 0;
}
.blocks_show_arrows div:hover {
  opacity: 0.8;
}
.blocks_show_next {
  margin-bottom: 18px;
}
.blocks_show_next,
.blocks_show_prev {
  cursor: pointer;
}
.blocks_show_next.disabled,
.blocks_show_prev.disabled {
  opacity: 0.3 !important;
  cursor: default;
}
.index_catalogues {
  /*border-bottom: 1px solid #d5dde3;*/
  /*margin-bottom: 70px;*/
  padding-bottom: 70px;
}
/*.index_search_wrapper {
	width: 355px;
	height: 41px;
	margin: 57px auto 0;
	position: relative;
}
.index_search_wrapper input {
	width: 100%;
	height: 47px;
	text-align: center;
	font-size: 14px;
	color: rgba(66,66,66,0.3);
	padding: 3px 35px 0 10px;
	border-radius: 23.5px;
	box-shadow: 0px 6px 24px 0 rgba(42, 42, 183, 0.14);
}
.index_search_wrapper img {
	position: absolute;
	right: 0;
	top: 0;
	width: 12px;
	cursor: pointer;
	z-index: 3;
	padding: 21px 20px;
	box-sizing: content-box;
}
.index_search_select {
	position: absolute;
	z-index: 1;
	overflow: hidden;
	height: 25px;
	padding-top: 27px;
	background: @white;
	font-size: 16px;
	text-align: center;
	width: 100%;
	margin-top: 16px;
}*/
/*.index_search_select {
	position: absolute;
	z-index: 1;
	overflow: hidden;
	height: 25px;
	padding-top: 27px;
	background: @white;
	font-size: 16px;
	text-align: center;
	width: 100%;
	margin-top: 16px;
}*/
.index_search_select {
  position: absolute;
  height: 0;
  z-index: 999;
  overflow: hidden;
  width: 173px;
  right: 16px;
  background: #FFFFFF;
  margin-top: 7px;
}
.index_search_select.active {
  height: auto;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
/*.index_search_option {
	margin: 6px 0;
	cursor: pointer;
	padding: 6px 0;
}
.index_search_option:hover {
	background-color: #ecf1f5;
}
form#index_search_form {
	position: relative;
	z-index: 2;
}*/
.fond_links_wrapper {
  margin-top: 66px;
  width: 940px;
}
.fond_link {
  float: left;
  margin-right: 30px;
}
.fond_link:last-child {
  margin-right: 0;
}
.fond_link a {
  color: inherit;
  font-size: 15px;
}
.fond_comment {
  font-size: 12px;
  font-weight: 200;
  padding: 7px 0 7px 11px;
  border-left: 1px solid #d3e0e5;
  width: 115px;
  margin-top: 10px;
  color: #90979d;
}
.index_news_wrapper {
  padding: 50px 0;
}
.index_news {
  float: left;
  width: calc(100% - 314px);
  position: relative;
  z-index: 2;
}
.index_news_header {
  display: inline-block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 35px;
  font-weight: bold;
  color: #232325;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.index_news_block .index_header {
  padding-bottom: 30px;
}
.index_nouvelle_main {
  /*width: 396px;
	height: 96%;*/
  display: flex;
  padding-bottom: 50px;
}
.index_pro .fall_through_link,
.index_collections_wrapper .fall_through_link {
  text-align: right;
}
.index_pro_header {
  display: inline-block;
  position: relative;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 35px;
  font-weight: bold;
  color: #232325;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.index_pro_header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 18px;
  background: url(../images/svg/tilde-icon.svg);
}
.index_pro_block {
  margin-bottom: 15px;
}
.index_pro_block .index_pro_image {
  display: block;
  width: 282px;
  height: 188px;
  background-size: cover;
  background-position: 50%;
}
.index_pro_block .index_pro_content {
  padding: 25px 20px 30px;
  background-color: #F5F8FA;
}
.index_pro_block .index_pro_content .index_pro_content_title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.26;
  color: #232325;
  margin-bottom: 15px;
}
.index_pro_block .index_pro_content .index_pro_content_text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 1.4;
  color: #232325;
  margin-bottom: 40px;
}
.index_pro_block .index_pro_content small {
  color: #B4BAC1;
  font-size: 13px;
  font-weight: 400;
}
.banners_show_controls {
  text-align: center;
}
.banners_show_up {
  background: url(../images/svg/caron-icon.svg);
  width: 16px;
  height: 10px;
  transform: rotate(180deg);
  display: inline-block;
  cursor: pointer;
  margin: 0 4px;
}
.banners_show_down {
  background: url(../images/svg/caron-icon.svg);
  width: 16px;
  height: 10px;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
}
.index_news_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.nouvelle_main_photo {
  display: block;
  flex: 0 0 384px;
  width: 100%;
  max-width: 384px;
  height: 256px;
  background-size: cover;
  background-position: center;
  margin-top: 6px;
  margin-right: 50px;
}
.nouvelle_main_content {
  /*max-height: 262px;*/
}
.nouvelle_desc a {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #898D91;
  margin-right: 10px;
}
.nouvelle_desc a:hover,
.nouvelle_desc a :focus {
  color: #B4BAC1;
  transition: color .2s;
}
.nouvelle_desc small {
  color: #B4BAC1;
  font-size: 13px;
}
.nouvelle_title {
  display: block;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  line-height: 1.26;
  font-weight: 700;
  color: #232325;
  margin-top: 15px;
}
.index_nouvelle_main .nouvelle_title {
  font-size: 22px;
  line-height: 1.36;
  font-weight: 700;
  color: #232325;
  margin-bottom: 25px;
}
.nouvelle_title:hover {
  color: #898D91;
}
.nouvelle_small_text {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 1.4;
  font-weight: 400;
  color: #232325;
}
.nouvelle_small_text p {
  margin: 0;
}
.index_nouvelle_block {
  width: calc(30%);
  margin-bottom: 30px;
}
a.rounded_btn_link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
  padding: 18px;
  border: 1px solid #003DF3;
  border-radius: 9px;
  text-align: center;
}
a.rounded_btn_link:hover,
a.rounded_btn_link :focus {
  color: #7900f3;
  transition: color .2s;
}
.fall_through_link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #003DF3;
}
.fall_through_link:hover,
.fall_through_link :focus {
  color: #7900f3;
  transition: color .2s;
}
a.index-header {
  display: inline-block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 35px;
  text-transform: uppercase;
  color: #232325;
  padding-bottom: 30px;
}
.index_columns {
  margin-top: 70px;
}
.index_column {
  flex: 0 0 384px;
  /*float: right;
	width: 384px;*/
  line-height: 1;
  position: relative;
  z-index: 2;
}
.subscription {
  display: block;
  width: 100%;
  text-align: center;
  padding: 60px 0 0;
}
.subscription .subscription__header {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.09px;
  line-height: 20px;
  color: #232325;
  padding: 0 0 20px;
}
.subscription .subscription__brief {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 1.36;
  color: #898D91;
}
.subscription .subscription__btn {
  display: block;
  margin: 30px auto 0;
}
.pro_column {
  float: right;
  width: 282px;
  line-height: 1;
  position: relative;
  z-index: 2;
}
.pro_column .index_header {
  font-size: 30px;
  padding-bottom: 30px;
}
.pro_column .index_header:before {
  content: "";
  position: absolute;
  top: -28px;
  left: 0;
  width: 50px;
  height: 18px;
  background: url(../images/svg/tilde-icon.svg);
}
.column_header {
  position: relative;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
}
.column_header .column_header_text {
  color: inherit;
  text-decoration: none;
}
.column_header b {
  font-weight: 600;
}
.column_header .column_header_small {
  font-size: 12px;
  text-transform: none;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  color: #003DF3;
}
.column_banner {
  display: block;
  width: 384px;
  height: 180px;
  margin-bottom: 20px;
}
.column_banner a {
  display: flex;
  border: none;
}
.column_banner a .column_banner_header {
  flex: 0 0 50%;
  display: none;
  font-size: 16px;
  font-weight: 600;
  color: #232325;
}
.column_banner img {
  flex: 0 0 50%;
  display: block;
  width: 100%;
  border-radius: 9px;
  /*-moz-transition: all 1s ease-out;
	-o-transition: all 1s ease-out;
	-webkit-transition: all 1s ease-out;*/
}
.column_banner img:hover {
  opacity: .8;
  transition: opacity .2s;
}
.banners_show_inner .column_banner a {
  width: 100%;
  height: 180px;
  overflow: hidden;
}
.banners_show_inner .column_banner a:hover {
  opacity: 1;
}
/*.column_banner img:hover {
	-webkit-transform: scale(1.03);
	-moz-transform: scale(1.03);
	-o-transform: scale(1.03);
}*/
.banners_show_wrapper {
  /*display: none;*/
  height: 384px;
  overflow: hidden;
}
.banners_show_inner {
  transition: all 300ms ease;
}
.banner_wrapper .column_banner div {
  font-size: 16px;
  color: #232325;
  text-align: center;
  padding: 27px 0 24px;
  background-color: #F8EE6E;
  border-radius: 4px;
  line-height: 16px;
  margin-top: 25px;
  margin-bottom: 0;
  font-weight: bold;
}
.column_text_block {
  border-bottom: 1px solid #d5dde3;
  margin-bottom: 15px;
}
.column_text_block p,
.column_text_block a {
  margin: 0 0 15px;
  display: block;
  color: inherit;
  text-decoration: none;
}
.column_text_block p {
  font-size: 12px;
  font-weight: 200;
  color: #90979d;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main_news_tag {
  font-size: 14px;
  font-weight: 200;
  color: #FFFFFF;
  padding: 8px 13px;
  background: #fa880e;
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  margin-top: -5px;
}
.column_text_block a {
  margin-bottom: 5px;
  line-height: 1.4;
  font-size: 16px;
  font-weight: bold;
}
.column_text_block .float_small_image_wrapper {
  height: 180px;
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
}
.learning_show_pins {
  margin: 12px 25px 0 0;
  text-align: right;
  height: 21px;
}
.learning_show_pin {
  cursor: pointer;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #E5E5E5;
  border-radius: 50%;
  margin-left: 8px;
}
.learning_show_pin.active {
  cursor: default;
  background-color: #232325;
}
.learning_show_slides {
  position: relative;
  min-height: 275px;
  overflow: hidden;
}
.learning_show_slides a {
  position: absolute;
  top: 0;
  left: 0;
}
.learning_show_slides img {
  width: 100%;
}
.centers_accent_image {
  font-size: 81px;
  color: #003DF3;
  /*width: 81px;*/
  text-align: center;
  margin-top: 122px;
}
.index_centers_wrapper {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 77px;
  text-align: center;
}
.index_centers_wrapper a {
  text-decoration: none;
  color: inherit;
}
.index_centers_wrapper b {
  font-weight: 600;
}
.index_centers_wrapper img {
  position: absolute;
  left: -84px;
  top: 0;
}
.index_centers_wrapper span {
  position: relative;
  z-index: 5;
}
.countries_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}
.cc_block {
  display: flex;
  flex: 0 0 calc(33.33333333%);
  align-items: center;
  max-width: 384px;
  height: 132px;
  margin-bottom: 20px;
  padding: 25px 30px;
  /*position: relative;*/
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.cc_block img {
  width: 50px;
  margin-right: 30px;
  border-radius: 19px;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
}
.cc_block span {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  color: #232325;
}
.cc_block:hover {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -o-transform: scale(1.03);
}
/*.cc_block:nth-child(even) {
	margin-right: 0;
}
.cc_block img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 44px;
	margin-top: -1px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.cc_block span {
	position: absolute;
	top: 52%;
	transform: translateY(-50%);
	transform-origin: center;
	left: 66px;
}*/
.partners_wrapper {
  text-align: center;
  /*padding-top: 50px;
	border-top: 1px solid @gray--light;*/
  margin-top: 80px;
}
.partners_wrapper .content-wrap {
  margin-top: 30px;
}
.partner_block {
  display: inline-block;
  vertical-align: middle;
  margin: 0 33px;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
.partner_block:first-of-type {
  margin-left: 0;
}
.partner_block:last-of-type {
  margin-right: 0;
}
.partners_wrapper a.button--with-shadow {
  margin-top: 30px;
}
.partners_more {
  display: inline-block;
  cursor: pointer;
  text-transform: lowercase;
  margin-top: 30px;
  padding: 12px 18px;
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 6px 24px rgba(42, 42, 183, 0.14);
}
a.partners_more {
  color: #003DF3;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.01em;
}
/*.partners_more:before {
	content: '';
	display: block;
	width: 38px;
	margin: 0 auto 20px;
	border: 1px solid #edf0f1;
}*/
.inner_navigation_text_wrapper .partners_wrapper {
  text-align: left;
}
.inner_navigation_text_wrapper .partner_block {
  display: inline-block;
  vertical-align: middle;
  margin: 20px 22px;
  width: 184px;
  text-align: center;
}
.inner_navigation_text_wrapper .partner_block img {
  max-width: 100%;
}
.index_full_banner {
  width: auto;
  margin: 60px auto 30px;
  display: block;
}
/*.catalogue_search_form {
	position: relative;
	margin: 0 18px;
	height: 44px;
	background-color: @white;
	border-bottom: 2px solid @color-main;
}
.catalogue_search_form a {
	position: absolute;
	right: 0;
	font-size: 14px;
}*/
.catalogue_content {
  font-family: inherit;
  line-height: 1.9;
  font-weight: 200;
  font-size: 16px;
  text-align: left;
}
.catalogue_content img {
  /*display: inline-block;*/
  margin: 0 auto 30px;
}
.catalogue_content h2 {
  text-align: center;
  margin: 40px 0;
}
.catalogue_content .index_full_banner {
  margin-bottom: -40px;
}
a.catalogue_banner {
  display: inline-block;
  width: 455px;
  margin-left: 15px;
}
.catalogue_line {
  width: 100%;
  height: 1px;
  background-color: #edf0f1;
  margin: 70px 0 40px;
}
.catalogue_link {
  position: absolute;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  top: 10px;
  right: 170px;
}
.catalogue_link:before {
  content: '';
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 11px;
  background: url(../images/svg/book-icon.svg) no-repeat center;
}
/*.event_content {
	width: 100%;
	padding: 20px 23px 36px 81px;
	font-weight: 200;
	font-size: 16px;
	margin-top: -1px;
	margin-bottom: -1px;
}*/
.event_credits.event_credits_inline {
  display: inline;
  border: none;
  padding: 0;
}
/*.event_content p {
	line-height: 27px;
	padding: 30px 80px 0 60px;
}*/
/*.event_about {
	margin-top: 9px;
}*/
.event_demo {
  font-size: 12px;
  /*font-weight: bold;*/
  color: #003DF3;
  margin-bottom: 36px;
}
.event_demo a {
  text-decoration: none;
}
.event_demo span {
  color: #afb5ba;
}
/*.event_organizer {
	float: right;
	text-align: right;
	font-size: 14px;
	color: #afb5ba;
}*/
.event_base {
  float: right;
  width: 450px;
}
h1.event_title {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  /*margin: 21px 0 38px;
	text-transform: none;*/
}
h1.event_title.event_title_float {
  float: left;
  width: 45%;
}
.event_base_row {
  width: 100%;
  padding: 20px 40px 10px 0;
  line-height: 25px;
  position: relative;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 200;
}
.event_base_cell {
  display: table-cell;
}
.event_base_cell.label {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  width: 110px;
  color: #232325;
}
.calendar {
  color: #003DF3;
}
.event_base_row a {
  text-decoration: none;
}
.event_base_row:nth-child(2n) {
  border-top: 1px solid #ebeff2;
  padding-top: 10px;
}
.event_base_row img {
  top: 50% !important;
  right: 9px;
  position: absolute !important;
  transform: translateY(-50%);
}
.open_place_popup {
  cursor: pointer;
}
img.open_popup_plan {
  /*float: none;*/
  margin-left: 9px;
  position: relative;
  cursor: pointer;
  /*width: 16px;*/
  display: none;
  right: 6px;
}
.event_image_wrapper {
  /*float: right;
	width: 40%;
	border: 1px solid red;*/
  float: left;
  margin-right: 64px;
}
.event_title.news_title {
  float: none;
  margin-right: 0;
  width: 100%;
  margin-top: 40px;
  margin-bottom: -17px;
}
.event_title.event_full_title {
  padding-right: 100px;
}
p.news_date {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 14px;
  color: #050505;
  margin-top: -10px;
}
.event_image {
  width: 454px;
  height: 255px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  position: relative;
  /*width: 100%;
	max-width: 454px;
	height: auto;*/
}
.event_image .float_flag img {
  width: 64px;
  height: auto;
}
.event_image_source {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 0.75em;
  line-height: 1.2;
  color: #c0c0c0;
  margin-top: 5px;
  /*padding-right: 14px;
	max-width: 454px;*/
}
.flag_enable .event_image_source {
  max-width: 390px;
}
.news_image .event_image_source {
  text-align: left;
}
.event_free_enter {
  text-decoration: underline;
  cursor: pointer;
}
#popup_enter_free .popup_text {
  font-size: 18px;
  line-height: 1.8;
}
#popup_plan .popup_text {
  text-align: left;
}
span.plan_day_wrapper {
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid #e6ecf1;
  margin-top: -1px;
  margin-left: -1px;
  width: 100px;
  text-align: center;
}
.popup_days_month {
  margin-top: 35px;
}
.popup_days_month:first-child {
  margin-top: 0;
}
.popup_month_name {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  background-color: #ecf1f5;
  font-weight: bold;
  margin-bottom: 15px;
}
.popup_days_day {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 48px;
  line-height: 1;
  font-weight: 200;
  width: 101px;
  height: 101px;
  margin-left: -1px;
  border: 1px solid #d5dde3;
  padding-top: 18px;
  text-align: center;
  display: inline-block;
  margin-bottom: -1px;
}
.popup_days_day a {
  color: #232325;
}
.popup_days_day small {
  font-size: 12px;
  color: #9e9f9f;
  margin-top: 10px;
  display: block;
}
/*.event_description {
	margin-top: 40px;
	padding-top: 20px;
	border-top: none;
	width: 100%;
	float: none;
	font-family: 'Open Sans', Arial, Helvetica;
	min-height: 240px;
}
.event_description .inner_quote {
	margin-top: 0;
	max-width: 720px;
	position: relative;
	top: 10px;
}*/
/*.event_description {
  padding: 10px 130px 0 110px;
}
.event_description p {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 200;
  line-height: 27px;
}
.event_description .inner_quote {
  padding: 30px 0 0 130px;
  font-family: @font-family--secondary;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.13;
}
.inner_quote:before {
  content: ' ';
  position: absolute;
  background-image: url(../images/svg/quote-icon.svg);
  background-size: cover;
  width: 70px;
  height: 44px;
  margin-left: -130px;
}*/
/* Ð‘Ð»Ð¾Ðº Ñ� Ð´Ð¾Ð¿. Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÐµÐ¹ */
.media_source {
  border-radius: 5px;
  font-weight: 700;
  display: block;
  text-align: center;
  line-height: 1;
  width: 386px;
  height: 36px;
  background: #E5E5E5 url(../images/cute_book.svg) no-repeat right 21px center;
  background-size: 20px auto;
  color: #003DF3;
  font-size: 12px;
  padding-top: 12px;
  margin: 0 auto;
}
.event_additional_wrapper {
  text-align: center;
}
.event_organizer {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
}
.event_organizer a {
  color: #003DF3;
  text-decoration: none;
}
.event_organizer:after {
  content: '';
  display: block;
  width: 64px;
  margin: 30px auto 0;
  border: 1px solid #edf0f1;
}
.event_additional_wrapper > span {
  font-size: 14px;
  font-weight: bold;
}
a.appendix_btn {
  display: none;
}
.btn_social {
  display: inline-block;
  width: 126px;
  height: 33px;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;
  vertical-align: middle;
  margin: 24px 3px 40px;
  border-radius: 16px;
}
.btn_vk {
  background-color: #5e83a7;
}
.btn_twitter {
  background-color: #00acee;
}
.btn_facebook {
  background-color: #52659c;
}
.btn_whatsapp {
  background-image: url(../images/svg/whatsapp.svg);
}
.btn_viber {
  background-image: url(../images/svg/viber.svg);
}
.btn_telegram {
  background-image: url(../images/svg/telegram.svg);
}
/* Ð�Ð¸Ð¶Ð½Ð¸Ð¹ Ð±Ð»Ð¾Ðº Ð½Ð° Ñ�Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ðµ Ð¼ÐµÑ€Ð¾Ð¿Ñ€Ð¸Ñ�Ñ‚Ð¸Ñ� */
.bottom_blocks_wrapper.event_content {
  padding: 0 !important;
}
.bottom_block {
  float: left;
  width: 50%;
  height: 110px;
  border: 1px solid #edf0f1;
  padding-top: 35px;
  text-align: center;
}
/*.bottom_block:last-child {
  border-right: none;
}*/
.bottom_block:first-child {
  border-right: none;
}
.bottom_block_content {
  display: inline-block;
}
.rounded_btn {
  float: left;
  margin: 0 6px;
  width: 38px;
  height: 38px;
  border-radius: 15px;
  background: #003DF3;
}
.rounded_btn:hover,
.rounded_btn :focus {
  opacity: .8;
  transition: opacity .2s;
}
.tg_btn {
  background: #229ED9 url(../images/svg/telegram-icon.svg) no-repeat center;
  background-size: 16px;
}
.tp_btn {
  background: #643FFF url(../images/svg/tp-icon.svg) no-repeat center;
  background-size: cover;
}
.vk_btn {
  background: #507299 url(../images/svg/vk-icon.svg) no-repeat center;
  background-size: 16px;
}
.fb_btn {
  background: #3b5998 url(../images/svg/fb-icon.svg) no-repeat center;
  background-size: 16px 16px;
}
.tw_btn {
  background: #00acee url(../images/svg/tw-icon.svg) no-repeat center;
  background-size: 16px;
}
.ig_btn {
  background: url(../images/svg/ig-icon.svg) no-repeat center;
  background-size: cover;
}
.yt_btn {
  background: #ff0000 url(../images/svg/youtube-icon.svg) no-repeat center;
  background-size: 16px;
}
.tagline {
  float: left;
  display: inline-block;
  margin: 2px 15px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  text-transform: uppercase;
}
.tagline small {
  display: block;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 12px;
  font-weight: 200;
  text-transform: none;
  color: #ababaa;
}
/**/
/*.event_socials {
	float: right;
	width: 213px;
	margin-right: -24px;
	margin-top: 10px;
	background-color: #ecf1f5;
	padding: 26px 0 16px;
	text-align: center;
	margin-bottom: 10px;
	margin-left: 20px;
	font-family: 'Open Sans', Arial, Helvetica;
}
.btn_social {
	float: left;
	display: block;
	width: 159px;
	height: 41px;
	text-align: center;
	font-weight: 400;
	margin: 0 auto 9px;
	color: @white;
	text-decoration: none;
	padding-top: 15px;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 13px;
}
.btn_vk {
	background-color: #5e83a7;
}
.btn_twitter {
	background-color: #00acee;
}
.btn_facebook {
	background-color: #52659c;
}*/
.add_calendar {
  width: 185px;
  border-top: 1px solid #d1dbe2;
  margin: 47px auto 0;
  padding-top: 16px;
}
.add_calendar a {
  color: #003DF3;
  text-decoration: none;
}
.addtocalendar {
  /*float: right;*/
  /*margin-top: 1px;*/
}
.atc-style-blue .atcb-list {
  width: 170px;
  border: 1px solid #bababa;
  border-radius: 2px;
  box-shadow: 0 0 5px #AAA;
  padding: 0;
  padding-top: 15px;
  margin: 0;
  font-weight: normal;
  text-align: center;
  font-style: normal;
}
.atc-style-blue .atcb-item:before {
  content: '';
  display: none;
}
.event_register {
  height: 130px;
  padding-top: 38px;
  padding-right: 0;
  border-top: 1px solid #e6ecf1;
  border-bottom: 1px solid #e6ecf1;
}
.btn_event_register {
  color: #FFFFFF;
  text-decoration: none;
  display: inline-block;
  width: 213px;
  height: 48px;
  background-color: #f6af16;
  border-radius: 100px;
  text-align: center;
  padding-top: 18px;
  line-height: 14px;
  float: left;
}
.event_register_text {
  float: left;
  font-weight: 400;
  color: #545c5f;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1;
  margin-top: 15px;
  margin-left: 27px;
}
.event_register_left {
  float: right;
  height: 130px;
  padding: 18px 30px 0;
  text-align: center;
  border-left: 1px solid #ecf1f5;
  margin-top: -38px;
  font-size: 14px;
  color: #afb5ba;
  line-height: 1;
}
.event_register_left b {
  color: #000;
  font-size: 48px;
  margin-top: 8px;
  display: inline-block;
}
.event_register_left span {
  font-size: 18px;
  color: #000;
}
.reg_how_to {
  text-align: center;
  margin-top: 20px;
  color: #000;
  font-size: 14px;
  background-color: #ecf1f5;
  padding: 20px 30px 18px;
}
.event_about {
  border-bottom: none;
}
.without_reg .event_about {
  padding-bottom: 1px;
}
.without_reg .event_credits {
  padding-top: 1px;
}
.event_credits {
  font-family: inherit;
  padding-top: 43px;
  /*padding-right: 81px;*/
  border-top: none;
}
.article-description__text table,
.inner_navigation_text_wrapper table,
.event_credits table {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.2;
  margin-bottom: 50px;
  border-collapse: collapse;
}
.article-description__text thead > tr > td,
.inner_navigation_text_wrapper thead > tr > td,
.event_credits thead > tr > td {
  font-weight: 800;
}
.article-description__text td,
th,
.inner_navigation_text_wrapper td,
th,
.event_credits table td,
th {
  padding: 20px 10px;
  border-bottom: 1px solid #ecf1f5;
}
.article-description__text tr,
.inner_navigation_text_wrapper tr,
.event_credits table tr {
  vertical-align: top;
}
.article-description__text tr:first-child td,
.inner_navigation_text_wrapper tr:first-child td,
.event_credits table tr:first-child td {
  border-top: 4px solid #000;
}
.article-description__text tr:last-child td,
.inner_navigation_text_wrapper tr:last-child td,
.event_credits table tr:last-child td {
  border: none;
}
.article-description__text table td:nth-child(1),
.inner_navigation_text_wrapper table td:nth-child(1),
.event_credits table td:nth-child(1) {
  width: 162px;
}
.article-description__text table tr > th,
.inner_navigation_text_wrapper table tr > th,
.event_credits table tr > th {
  text-align: left;
  border-bottom: 4px solid #000;
}
.event_additional {
  font-family: inherit;
}
.fotorama_counter {
  display: none;
  color: #bbc1c6;
  font-size: 14px;
  float: right;
  margin-right: -23px;
  width: 81px;
  z-index: 5;
  position: relative;
  bottom: -15px;
  text-align: center;
}
.fotorama_comment {
  font-size: 14px;
  color: #bbc1c6;
  margin-top: 13px;
  margin-bottom: 30px;
  text-align: center;
}
/*.inner_navigation_text_wrapper > .linked_doc:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	transform: rotate(-90deg);
	background-image: url(../images/svg/caron-icon.svg);
	background-size: contain;
	width: 12px;
	height: 8px;
	right: 13px;
}*/
.event_content ul,
.inner_navigation_text_wrapper ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 4em;
  /*margin-top: -12px;*/
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 200;
}
.event_content ul ul,
.inner_navigation_text_wrapper ul ul {
  margin-top: 10px;
}
.event_content ul li,
.inner_navigation_text_wrapper ul li {
  padding: 0;
  margin-bottom: 17px;
  line-height: 1.3;
}
.event_content ul > li:before,
.inner_navigation_text_wrapper ul > li:before {
  color: #003DF3;
  content: '\2014\a0';
  float: left;
  margin-left: -2em;
}
.event_content ul sup,
.inner_navigation_text_wrapper ul sup {
  position: relative;
  top: -0.5em;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.additional_links {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  margin-top: 30px;
}
.inner_navigation_text_wrapper .ui-selectmenu-button {
  /*width: 293px !important;*/
}
.inner_navigation_text_wrapper .list_type.listing_wrapper {
  margin-top: 37px;
}
.event_status_text {
  font-size: 16px;
  color: #232325;
}
.more_events_wrapper {
  margin-top: 20px;
  position: relative;
  min-height: 275px;
}
.more_events_text {
  width: 212px;
  height: 100%;
  background-image: url(../images/more_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  padding: 25px;
  float: left;
  position: absolute;
}
.more_events_wrapper .blocks_show_wrapper {
  float: left;
  position: relative;
  left: 246px;
  width: 680px;
  color: inherit;
}
.more_events_blocks .float_small_block {
  margin-right: 51px;
}
.more_events_blocks .float_small_date {
  visibility: hidden;
}
.more_events_wrapper .blocks_show_arrows {
  margin: 0;
  position: absolute;
  bottom: 25px;
  left: 25px;
}
.actual_events_text {
  font-weight: 200;
  font-size: 18px;
}
.actual_events_text b {
  display: block;
  font-size: 36px;
  margin-bottom: 3px;
  font-weight: 400;
}
.actual_events_block .float_small_image {
  margin-top: 0;
}
.switcher_wrapper {
  margin-top: 37px;
  width: 100%;
}
.switch_block {
  width: 50%;
  height: 53px;
  font-size: 24px;
  text-transform: uppercase;
  color: #545c5f;
  background-color: #ecf1f5;
  float: left;
  text-align: center;
  position: relative;
  cursor: pointer;
  line-height: 24px;
  padding-top: 7px;
}
.switch_width_1.switch_block {
  width: 40%;
  padding-left: 45px;
}
.switch_width_2.switch_block {
  width: 24%;
  padding-left: 45px;
}
.switch_width_3.switch_block {
  width: 36%;
  padding-left: 45px;
}
.switch_block span {
  position: relative;
  top: 11px;
}
.switch_block.active {
  color: #FFFFFF;
  background-color: #f6af16;
  cursor: default;
}
.switch_type {
  display: none;
  background: #ef9912 url(../images/switch_list.png) no-repeat center;
  width: 53px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.list_type .switch_type {
  background-image: url(../images/switch_blocks.png);
}
.switch_block.active .switch_type {
  display: block;
}
.listing_block {
  display: none;
  color: #FFFFFF;
}
.listing_block.active {
  display: block;
}
.news_wrapper.listing_block {
  color: #000;
}
.filters_wrapper {
  margin-top: 33px;
  text-align: left;
  line-height: 10px;
  vertical-align: top;
}
.filters_wrapper select {
  width: 213px;
}
.filters_wrapper .ui-selectmenu-button,
.filters_wrapper .date_wrapper {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.filters_wrapper .ui-selectmenu-button {
  margin-right: 32px;
}
.submit_link {
  text-align: left;
  width: 200px;
  margin-top: -8px;
  border-top: 1px solid #ecf1f5;
  border-bottom: 1px solid #ecf1f5;
  padding: 0 0 8px;
  display: inline-block;
  margin-left: 25px;
}
.list_type .submit_link {
  float: none;
  display: block;
  margin-top: 30px;
  padding: 15px 0;
  text-align: center;
  width: 100%;
  margin-left: 0;
}
.submit_link a {
  text-decoration: none;
  color: #1ca1da;
  font-size: 18px;
}
.submit_link span {
  text-transform: uppercase;
  display: none;
  margin-top: 15px;
  font-weight: 600;
}
.list_type .submit_link span {
  display: block;
}
.submit_link small {
  font-size: 12px;
  color: #a2a9ac;
  display: inline-block;
  margin-top: 10px;
  line-height: 1.3;
  width: 160px;
  margin-left: 10px;
}
.list_type .submit_link small {
  display: block;
  margin-left: 0;
  width: auto;
}
.archive_link {
  border-top: 1px solid #ecf1f5;
  border-bottom: 1px solid #ecf1f5;
  display: none;
  float: none;
  margin-top: 30px;
  padding: 15px 0;
  text-align: center;
  width: 100%;
  margin-left: 0;
}
.list_type .archive_link {
  display: block;
}
.archive_link a {
  text-decoration: none;
  color: #1ca1da;
  font-size: 18px;
}
.archive_link span {
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 600;
  display: block;
  line-height: 1.1;
}
.input-daterange {
  width: 213px;
  height: 33px;
  border-radius: 100px;
  background-color: #FFFFFF;
  border: 1px solid #dfe7ee;
  position: relative;
}
.input-daterange.selected:before {
  content: 'â€“';
  position: absolute;
  left: 75.5px;
  top: 9px;
  font-weight: 200;
  font-size: 16px;
  color: #003DF3;
  line-height: 16px;
}
.list_type .input-daterange.selected:before {
  left: 76.5px;
}
.input-daterange:after {
  content: ' ';
  display: block;
  position: absolute;
  background-image: url(../images/calendar_blue.png);
  width: 18px;
  height: 20px;
  top: 4px;
  right: 15px;
}
.input-daterange input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  text-align: left;
  color: #003DF3 !important;
  outline: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
}
.start_date {
  z-index: 1;
  padding-left: 15px;
  color: #003DF3 !important;
  opacity: 1;
}
.start_date[disabled] {
  -webkit-text-fill-color: #003DF3;
  opacity: 1;
  color: #003DF3;
}
.input.start_date {
  width: 26%;
}
.list_type input.start_date {
  width: 60%;
}
input.end_date {
  z-index: 2;
  position: relative;
  padding-left: 0;
  left: 16px;
  width: 33%;
}
.list_type input.end_date {
  left: 26px;
  width: 40%;
}
.listing_wrapper {
  margin-top: 27px;
}
.list_type.listing_wrapper {
  margin-top: 0;
}
.inner_navigation_wrapper {
  /*margin-top: 16px;
	margin-bottom: 90px;*/
}
p.bordered_block {
  border-top: 1px solid #e6ecf1;
  border-bottom: 1px solid #e6ecf1;
  margin: 0;
  margin-top: -1px;
  padding: 13px 0 12px;
  font-size: 16px;
  line-height: 18px;
}
p.bordered_block .contact_comment {
  font-size: 14px;
  float: right;
  text-align: right;
  padding-top: 2px;
  line-height: 13px;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
p.bordered_block b,
p.bordered_block strong {
  font-weight: 600;
}
a.about_link,
.inner_navigation_text_wrapper a.about_link {
  display: inline-block;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 14px;
  text-decoration: none;
}
.inner_navigation_text_wrapper .contact_comment a {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.contact_important {
  margin-top: 30px;
  font-size: 14px;
}
.contact_important img {
  float: left;
  margin-right: 20px;
  margin-top: 1px;
  height: 30px;
}
.inner_pages_banner {
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  padding: 20px 0 18px;
  background-color: #f67f16;
  line-height: 16px;
  margin-bottom: 0;
  font-weight: 200;
}
.scheme_header {
  color: #232325;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  padding-top: 12px;
}
/*.scheme_header span {
	font-size: 29px;
	font-weight: bold;
	padding: 0 0 0 35px;
	height: 53px;
	display: inline-block;
}*/
.scheme_switcher {
  float: right;
  margin-right: 95px;
}
.scheme_switch_block {
  cursor: pointer;
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  padding-top: 6px;
  margin: 0 7px;
}
.scheme_switch_block.active {
  cursor: default;
  color: #FFFFFF;
  font-weight: normal;
  background-color: #0404CF;
  border: 3px solid #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 243, 0.34);
  margin: -3px 4px 0 4px;
}
.etages_wrapper {
  margin-top: 40px;
}
.etage_wrapper {
  display: none;
}
.etage_wrapper.active {
  display: block;
}
.sections_switcher {
  float: left;
  width: 50%;
  position: relative;
  margin: 0;
}
.etage_scheme_wrapper {
  float: right;
  width: 455px;
  height: 384px;
  position: relative;
}
.etage_scheme_wrapper img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.etage_back {
  z-index: 1;
}
.etage_section {
  z-index: 2;
}
.etage_section_info_btn {
  position: absolute;
  z-index: 3;
  text-align: center;
  left: 187px;
  top: 200px;
  cursor: pointer;
}
.etage_section_info_btn.active {
  display: block;
}
.info_btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0 auto 15px;
  background: #FFFFFF url(../images/svg/info-icon.svg) no-repeat center;
  background-size: 9px 22px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 243, 0.34);
}
.active_info,
.selected .normal_info {
  display: none;
}
.selected .active_info {
  display: block;
}
.etage_section_info_btn img {
  width: auto;
  position: relative;
  height: 46px;
}
.etage_section_info_btn span {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #003DF3;
  text-transform: uppercase;
}
.section_switch_block {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  color: #003DF3;
  border-bottom: 1px solid #EDF0F1;
  padding: 11px 14px;
  max-width: 415px;
  margin-left: 20px;
}
ol.sections_switcher li {
  counter-increment: list;
  list-style-type: none;
  position: relative;
}
ol.sections_switcher li:before {
  color: #232325;
  content: counter(list) ".";
  width: 26px;
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 0;
  margin-left: -3.3em;
}
/*.section_switch_block:before {
	content: ' ';
	display: inline-block;
	background: url(../images/svg/caron-icon.svg) no-repeat;
	width: 12px;
	height: 8px;
	background-size: contain;
	position: absolute;
	right: 11px;
	margin-top: 8px;
	transform: rotate(-90deg);
}*/
.section_switch_block:last-child {
  border-bottom: none;
}
.section_switch_block.active {
  cursor: default;
  background: #EDF0F1;
}
.section_info {
  display: none;
}
.section_info.active {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  padding: 40px;
  border: 1px solid #ebeff2;
  border-radius: 3px;
  z-index: 10;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
.section_info_header {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.section_info_text {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  margin-top: 25px;
  font-size: 14px;
  line-height: 24px;
}
#section_info_blocks_wrapper {
  display: none !important;
}
.section_info_print {
  margin-top: 50px;
  border-top: 4px solid #232325;
  padding-top: 30px;
  font-size: 14px;
  color: #003DF3;
  text-align: center;
}
.section_info_print:hover {
  opacity: 0.8;
}
.section_info_print img {
  position: absolute;
  left: 50px;
  margin-top: -12px;
  cursor: pointer;
}
.section_info_print span {
  position: relative;
  top: -10px;
  cursor: pointer;
}
.section_info_close {
  background-image: url(../images/svg/close.svg);
  width: 28px;
  height: 28px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.section_info_close:hover {
  opacity: .9;
}
.catalogue_search_wrapper {
  width: 100%;
  /*padding: 0 20%;*/
}
/* Ðž Ð±Ð¸Ð±Ð»Ð¸Ð¾Ñ‚ÐµÐºÐµ */
.about_blocks_wrapper {
  width: 1200px;
  margin: 20px auto 0;
  position: relative;
}
.catalogue_wrapper,
.about_blocks_wrapper {
  width: 1200px;
}
.about_block {
  width: 270px;
  height: 270px;
  display: block;
  float: left;
  margin: 0 15px 31px;
  background-color: #FFFFFF;
  border: 4px solid #003DF3;
  text-decoration: none;
  font-size: 24px;
  text-transform: uppercase;
  color: #003DF3;
  line-height: 1.2;
  padding: 23px 21px;
  position: relative;
}
.about_block.with_background {
  background-image: url(../images/res_banner.jpg);
  color: #FFFFFF;
  border: none;
}
.about_block.with_icon {
  background-color: #003DF3;
  color: #FFFFFF;
}
.about_block.double {
  width: 570px;
}
.about_block small {
  display: block;
  text-transform: none;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 14px;
  line-height: 24px;
  font-weight: lighter;
  letter-spacing: 1px;
}
.about_block img,
.about_block svg {
  position: absolute;
  bottom: 17px;
  right: 15px;
}
.bordered_right {
  float: right;
  font-size: 16px;
}
.bordered_right a {
  color: #003DF3;
  text-decoration: none;
  border-bottom: 1px solid #003DF3;
  line-height: 18px;
  display: inline-block;
}
p.culture_center_text {
  margin: 45px 0;
}
.inner_page_teaser_image {
  /* margin: 36px 0 55px;*/
}
.inner_page_teaser_image img {
  width: 100%;
  position: relative;
  top: 5px;
}
/* SEARCH RESULTS */
.search-page__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}
.search-page__phrase {
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 15px;
  font-weight: 200;
  color: #B4BAC1;
}
.search-page__phrase span {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #232325;
}
.search-page__filters label {
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}
.search-page__filters label + label {
  padding-left: 30px;
}
.search-page__results {
  width: 100%;
  max-width: 800px;
}
@media screen and (max-width: 900px) {
  .search-page__filters {
    width: 100%;
  }
}
.search-results__section:not(:last-child) {
  margin-bottom: 60px;
}
a.search-results__heading {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
  color: #232325;
  margin: 0;
  margin-bottom: 30px;
}
a.search-results__heading:hover {
  color: #898D91;
  transition: all .3s;
}
ol.search-results__list {
  padding-left: 15px;
  margin-top: 30px;
  padding-right: 0;
}
ol.search-results__list li {
  width: 100%;
  max-width: 880px;
  padding-right: 60px;
  padding-bottom: 33px;
  margin-bottom: 33px;
  font-size: 17px;
  color: #B4BAC1;
  line-height: 1.85;
  padding-left: 15px;
  border-bottom: 1px solid #edf0f1;
}
ol.search-results__list li:last-child {
  border-bottom: none;
}
a.search-results__title {
  display: block;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  color: #232325;
  text-decoration: none;
}
a.search-results__title:hover {
  color: #898D91;
  transition: all .3s;
}
.search-results__type {
  font-size: 12px;
  font-weight: 600;
  color: #898D91;
  text-transform: uppercase;
}
a.search-results__title + .search-results__type {
  margin-top: 10px;
  margin-bottom: 20px;
}
.search-results__date {
  font-size: 13px;
  font-weight: 400;
  color: #B4BAC1;
  padding-top: 15px;
}
p.snippet {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.85;
  color: #232325;
  overflow: hidden;
  margin: 0;
  margin-top: 20px;
}
.search_documents .linked_documents_wrapper {
  width: 880px;
}
.search_documents .linked_doc {
  padding-top: 0;
  margin-top: 26px;
}
/* Ð Ð°Ð·Ð²Ð¾Ð´Ð½Ð°Ñ� ÐšÐ¦ */
.cc_banner_wrapper {
  width: 100%;
  height: 272px;
  overflow: hidden;
  position: relative;
}
.cc_banner_image {
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;
}
.cc_banner_overlay {
  background: url(../images/cc_overlay.png) no-repeat 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.cc_header_wrapper {
  width: 470px;
  position: absolute;
  left: 50%;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.17;
  padding-top: 60px;
  top: 27px;
  z-index: 3;
  color: #FFFFFF;
  margin-bottom: 43px;
  margin-left: -235px;
  text-align: center;
}
.cc_header_wrapper b {
  font-weight: 600;
}
.cc_header_wrapper img {
  position: absolute;
  left: -51px;
  top: 0;
  width: 86px;
}
.cc_header_wrapper span {
  position: relative;
  z-index: 5;
  font-size: 20px;
}
.cc_blocks_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}
/* Ð�Ð¾Ð²Ð¾Ñ�Ñ‚Ð¸ */
.inner_navigation_text_wrapper .filters_wrapper {
  float: none;
  margin: 0;
  text-align: left;
}
.inner_navigation_text_wrapper .filters_wrapper,
.inner_navigation_text_wrapper .listing_wrapper {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
.ui-widget {
  font-family: inherit;
}
.ui-selectmenu-button,
.ui-state-hover.ui-selectmenu-button,
.ui-state-focus.ui-selectmenu-button {
  /*min-width: 200px !important;
	border: none;*/
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  outline: none;
  /*background: @white;
	border-bottom: 1px solid #cfd9e1;
	border-radius: 3px;
	padding: 10px 38px 10px 14px;
	text-transform: uppercase;*/
}
.ui-state-hover.ui-selectmenu-button,
.ui-state-focus.ui-selectmenu-button {
  opacity: 0.9;
}
.ui-selectmenu-button.ui-selected {
  /*color: @white !important;
	background-color: #2ba2d8 !important;*/
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  font-weight: 200;
  background-color: transparent;
  color: #57606d;
}
.ui-selectmenu-button span.ui-selectmenu-text {
  padding: 0 16px 0 0;
  line-height: unset;
  max-width: 150px;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: 0;
}
.ui-selectmenu-button span.ui-icon {
  width: 8px;
  height: 5px;
  background: url(../images/svg/caron-icon.svg) 0 0;
  transform: rotate(0deg);
  background-size: cover;
  margin-top: -1px;
  right: 0;
}
ui-selectmenu-menu.ui-front.ui-selectmenu-open span.ui-icon {
  background: url(../images/svg/caron-icon.svg) no-repeat center;
  /*transform: rotate(-90deg);*/
}
.ui-selectmenu-button.ui-selected span.ui-icon {
  background-position: 0 -5px;
}
.ui-front {
  z-index: 100;
}
.ui-selectmenu-menu .ui-menu {
  width: 256px;
  max-height: 338px;
  overflow: auto;
  border-radius: 0;
  background-color: #FFFFFF;
  font-family: inherit;
  font-size: 16px;
  /*margin-top: 20px;*/
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
.ui-menu .ui-menu-item {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  padding: 20px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #717172;
  line-height: 1.2;
  /*margin-top: -1px !important;*/
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: 0 !important;
  color: #232325;
  background-color: #edeff1;
}
.ui-menu .ui-menu-item:first-child {
  border: none !important;
}
.fotorama__nav__frame.fotorama__active .fotorama__dot {
  width: 8px;
  height: 8px;
  background-color: #232325;
  border: none;
  border-radius: 50%;
}
.fotorama__dot {
  width: 8px;
  height: 8px;
  background-color: #E5E5E5;
  border: none;
  border-radius: 50%;
}
.fotorama__nav,
.fotorama__stage,
.fotorama,
.fotorama__stage__frame {
  overflow: visible;
}
.fotorama {
  margin-top: 40px;
  margin-bottom: 60px;
}
.fotorama__stage {
  background-color: #FFFFFF;
}
.fotorama__arr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fotorama__arr--next {
  width: 36px;
  height: 36px;
  background: #f6f6f6 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 24px;
  transform: rotate(180deg) !important;
  border-radius: 12px;
}
.fotorama__arr--prev {
  width: 36px;
  height: 36px;
  background: #f6f6f6 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 24px;
  border-radius: 12px;
}
.fotorama__arr--disabled {
  opacity: 0.05;
}
.fotorama__video-close {
  display: none;
}
.fotorama__caption {
  position: absolute;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  line-height: 1.5;
  width: 100%;
  font-size: 14px;
  color: #2c2c2c;
  opacity: 0.3;
  margin-top: 14px;
  margin-bottom: 0;
  text-align: center;
  transform: translateY(100%);
}
.fotorama__caption__wrap {
  background-color: transparent;
}
.inner_navigation_text .fotorama__arr--next {
  right: -161px;
}
.inner_navigation_text .fotorama__arr--prev {
  left: 0;
  background-color: rgba(255, 255, 255, 0);
}
.inner_navigation_text .fotorama__fullscreen-icon {
  right: -78px;
}
.inner_navigation_text .fotorama_counter {
  margin-right: 0;
}
.inner_navigation_text .fotorama__stage__shaft {
  left: 81px;
}
#popup_reader.popup_box {
  position: absolute;
  background-color: #FFFFFF;
  width: 1020px;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 80px 110px 60px;
  box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 60px;
}
#popup_reader.popup_box .decoration_left {
  display: block;
  position: absolute;
  bottom: 15%;
  left: 0;
  width: 80px;
  height: 170px;
  background: url(../images/svg/left-shape.svg) center no-repeat;
  background-size: contain;
}
#popup_reader.popup_box .decoration_right {
  display: block;
  position: absolute;
  top: 30%;
  right: 0;
  width: 75px;
  height: 198px;
  background: url(../images/svg/right-shape.svg) center no-repeat;
  background-size: contain;
}
.modal_cta {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 35px;
  text-transform: uppercase;
  color: #232325;
  text-align: center;
  background-color: #E5E5E5;
  padding: 20px 0;
  margin: 60px 0 40px;
}
ol.modal_list {
  counter-reset: item;
  margin-left: 0;
  padding-left: 5px;
}
ol.modal_list li {
  position: relative;
  padding-left: 2em;
  margin: 0 0 20px;
  transition: all 0.2s ease-in-out;
  list-style: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
ol.modal_list li:before {
  content: counter(item) ". ";
  counter-increment: item;
  position: absolute;
  top: 0;
  left: 1.8em;
  width: 1.8em;
  height: 1.8em;
  padding: 0;
  transition: all 0.3s ease-in-out;
  color: #B4BAC1;
  margin-left: -2em;
}
ol.modal_list li span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #9A9CA5;
  padding: 20px 0 10px;
}
ul.modal_list {
  list-style-type: none;
  padding-left: 2em;
}
ul.modal_list li {
  list-style: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
ul.modal_list li.yesto {
  color: #4a4a4a;
}
ul.modal_list li.yesto:before {
  content: '';
  float: left;
  margin-left: -2em;
  display: block;
  width: 11px;
  height: 11px;
  background: url(../images/svg/checkbox-icon.svg) center no-repeat;
  background-size: 11px;
  margin-top: .5em;
  margin-right: 0;
  padding-left: 0;
}
ul.modal_list li.yesto a {
  color: #003DF3;
}
ul.modal_list li.yesto a:hover,
ul.modal_list li.yesto a :focus {
  color: #7900f3;
  transition: color .2s;
}
ul.modal_list li.noto {
  color: #d1d1d1;
}
ul.modal_list li.noto:before {
  content: '';
  float: left;
  margin-left: -2em;
  display: block;
  width: 11px;
  height: 11px;
  background: url(../images/svg/fail.svg) center no-repeat;
  background-size: 11px;
  margin-top: .5em;
  margin-right: 0;
  padding-left: 0;
}
ul.modal_list li.noto a {
  color: #bbbbfc;
}
ul.modal_list li.noto a:hover,
ul.modal_list li.noto a :focus {
  color: #a3a3fb;
  transition: color .2s;
}
.modal_parts {
  display: flex;
  justify-content: space-between;
}
.modal_parts .modal_part {
  flex: 0 0 48%;
  text-align: left;
}
.modal_parts .modal_part .modal_title {
  font-size: 24px;
  font-weight: 700;
  color: #232325;
  padding-bottom: 15px;
}
.modal_parts .modal_part .modal_subtitle {
  display: block;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #9A9CA5;
}
.modal_welcome {
  margin: 120px auto 105px;
  text-align: center;
}
.modal_welcome span {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 35px;
  text-transform: uppercase;
  color: #232325;
  margin-bottom: 30px;
}
.modal_welcome span:before {
  content: '';
  display: block;
  width: 50px;
  height: 18px;
  background: url(../images/svg/tilde-icon.svg) center no-repeat;
  background-size: contain;
  margin: 0 auto 20px;
}
.modal_welcome a.btn {
  display: block;
  width: 160px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .25px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #003DF3;
  border-radius: 4px;
  padding: 13px 35px;
  margin: 0 auto;
}
.modal_welcome a.btn:hover,
.modal_welcome a.btn :focus {
  background-color: #7900f3;
  transition: background-color 0.2s;
}
.modal_rules {
  text-align: center;
}
.modal_rules a {
  font-size: 13px;
  font-weight: 400;
  color: #A8A8FC;
}
#gradient_popup_wrapper {
  position: fixed;
  display: none;
  z-index: 9000;
  width: 100%;
  height: 300px;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0));
}
#popup_social {
  background-color: #FFFFFF;
  width: 460px;
  position: absolute;
  bottom: -45px;
  right: 15px;
  text-align: center;
  padding: 40px 30px 40px;
  border-radius: 4px;
  box-shadow: 0 0 133px rgba(0, 0, 0, 0.3);
  margin-bottom: 60px;
}
#popup_social.tagline {
  font-size: 16px;
}
#popup_wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.popup_box {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 2em;
  display: none;
  border-radius: 4px;
  box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.11);
}
@media screen and (max-width: 768px) {
  .popup_box {
    top: 0;
    height: 100vh;
    padding: .5em;
    overflow: auto;
  }
}
.popup_box .section_info.active {
  padding: 40px;
}
.popup_exit,
.popup_close {
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1em;
  width: 32px;
  height: 32px;
  background-color: #E5E5E5;
  background-image: url(../images/svg/exit-black.svg);
  border-radius: 12px;
}
.popup_exit:hover,
.popup_close:hover {
  background-color: #d8d8d8;
  transition: all .3s;
}
.popup_title {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}
.popup_box .form_control {
  width: 275px;
  position: relative;
  margin: 0 auto 22px;
}
.popup_box .form_control.req:after {
  position: absolute;
  content: '*';
  display: block;
  bottom: 12px;
  right: -7px;
  font-size: 14px;
  color: #90979d;
}
.popup_box .form_control label {
  font-size: 14px;
  color: #90979d;
  font-weight: 200;
  margin-bottom: 5px;
}
.popup_box .form_control input {
  width: 100%;
  font-size: 16px;
  height: 31px;
}
.popup_text,
.popup_error_text {
  font-size: 14px;
  line-height: 2.2;
}
.popup_text p,
.popup_error_text p {
  margin-top: 0;
  margin-bottom: 30px;
}
.popup_error_text {
  display: none;
}
#popup_subscribe label {
  display: block;
  font-size: 14px;
  margin: 15px auto 0;
}
#popup_subscribe label a {
  color: #003DF3;
}
#popup_subscribe label a:hover,
#popup_subscribe label a :focus {
  color: #7900f3;
  transition: color .2s;
}
.subscribe_accept {
  font-size: 13px;
  font-weight: 400;
  color: #B4BAC1;
}
.subscribe_accept a {
  color: #a8a8fc;
}
.subscribe_email {
  width: 90%;
  height: 47px;
  font-size: 14px;
  padding: 0 20px;
  background-color: #f5f6f7;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  margin-top: 20px;
}
.subscribe_events {
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 20px;
}
.subscribe_result_text {
  margin: 40px 0 20px;
  color: #37C537;
}
.popup_box button,
#ask_question button {
  display: inline-block;
  height: 47px;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.34px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  background-color: #003DF3;
  border-radius: 5px;
  padding: 0 30px;
}
.popup_box button:hover,
#ask_question button:hover {
  opacity: 0.8;
}
#popup_event button {
  letter-spacing: normal;
  padding-left: 23px;
  padding-right: 23px;
  width: 275px;
}
#popup_subscribe .subscribe_events_list {
  margin: 0 50px;
}
#popup_subscribe .subscribe_events_list label {
  float: left;
  display: block;
  width: 50%;
  height: 17px;
  text-align: left;
  margin-bottom: 11px;
  padding-left: 20px;
}
#popup_subscribe .subscribe_events_list label .icheckbox_minimal {
  margin-left: -25px;
}
#popup_event input {
  text-align: center;
  padding-left: 0;
}
#popup_map {
  width: 800px;
}
#popup_map .section_info {
  display: block;
  width: 48%;
  float: left;
  border: none;
  position: relative;
  box-shadow: none;
}
.popup_map_image {
  width: 48%;
  float: right;
  margin-top: 50px;
}
.popup_map_image img {
  width: 100%;
}
#popup_map .section_info_content {
  text-align: left;
}
.req_comment {
  color: #90979d;
  font-size: 14px;
  position: relative;
  text-align: center;
  margin-top: 16px;
}
#popup_event {
  padding: 38px 0 0;
}
#popup_reader.popup_box {
  width: 90%;
  max-width: 940px;
  text-align: left;
  vertical-align: top;
}
.reader_left_text {
  display: inline-block;
  width: 46%;
  vertical-align: top;
}
.reader_splitter {
  display: block;
  width: 1px;
  height: 92%;
  background-color: #ecf1f5;
  position: absolute;
  left: 50%;
  top: 4%;
}
.reader_right_text {
  display: inline-block;
  width: 45%;
  vertical-align: top;
  margin-left: 8%;
}
/* ÐžÐ±Ñ€Ð°Ñ‚Ð½Ð°Ñ� Ñ�Ð²Ñ�Ð·ÑŒ */
#ask_question {
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  text-align: center;
}
#ask_question input,
#ask_question textarea {
  margin-top: 0;
  margin-bottom: 22px;
  width: 100%;
  font-size: 18px;
  padding: 6px 35px;
  font-weight: 200;
}
#ask_question textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 225px;
}
/* Ð¡Ñ‚Ð°Ð¹Ð»Ð¸Ð½Ð³ iCheck */
/*.icheckbox_minimal {
	border: 2px solid @gray--darken;
	border-radius: 2px;
	width: 19px;
	height: 19px;
	display: inline-block;
	position: relative;
	top: 4px;
	margin-right: 6px;
}
.icheckbox_minimal.checked {
	background: @color-main url(../images/svg/caron-white-icon.svg) no-repeat center;
}
svg.icheckbox_minimal.checked {
	fill: @color-main;
}
.icheckbox_minimal input {
	opacity: 0 !important;
}*/
/* Ð¡Ñ‚Ð°Ð¹Ð»Ð¸Ð½Ð³ Ð°ÐºÐºÐ¾Ñ€Ð´ÐµÐ¾Ð½Ð° */
.tooltip {
  display: inline;
}
.tooltip a {
  position: relative;
}
.tooltip a button.tooltip-btn {
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  color: #232325;
  padding: 0 5px;
  background: #E5E5E5;
  border: 0;
  border-radius: 4px;
  margin-left: 6px;
  margin-top: -2px;
}
.tooltip a span {
  display: none;
}
.tooltip a:hover {
  text-indent: 0;
  opacity: 1;
}
.tooltip a:hover span {
  z-index: 100;
  display: block;
  position: absolute;
  width: 250px;
  font-size: 12px;
  text-align: left;
  color: #232325;
  padding: 10px 20px;
  margin: 10px;
  background-color: #f5f6f7;
  border: 1px solid #E5E5E5;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
/*#accordion {
	position: relative;
}
#accordion ul.ui-accordion-header {
	font-size: 18px;
	font-style: normal;
	color: @gray--darken;
	border: none;
	border-top: 1px solid #ecf1f5;
	border-bottom: 1px solid #ecf1f5;
	border-radius: 0;
	background: @white;
	margin: -1px 0 0;
	outline: none;
	line-height: 1;
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 44px;
}
#accordion ul li {
	margin-bottom: 0;
}
#accordion ul.ui-accordion-header li:before {
	content: '';
	display: none;
}
#accordion .ui-widget-content {
	background-color: #ecf1f5;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
	background-position: center;
	position: absolute;
	left: 4px;
	margin-top: 8px;
}
.ui-state-default .ui-icon {
	background: url(../images/svg/caron-icon.svg) no-repeat;
	width: 12px;
	height: 8px;
	background-size: contain;
	transform: rotate(-90deg);
}
.ui-state-active .ui-icon {
	background: url(../images/svg/caron-icon.svg) no-repeat;
	width: 12px;
	height: 8px;
	background-size: contain;
	transform: none;
}
.ui-accordion .ui-accordion-content {
	border: none;
	border-radius: 0;
	line-height: 1.5;
	padding: 30px 45px;
}
.ui-accordion-content p a {
	color: @color-main;
	font-size: 16px;
}
.ui-accordion-content .more {
	color: @color-main;
	font-family: @font-family--secondary;
	font-size: 14px;
	font-weight: bold;
}
*/
.error_content {
  /*background-color: @color-main;*/
  width: 100%;
  max-width: 800px;
  padding: 50px 0 110px;
  color: #000;
  text-align: center;
  margin: 25px auto -100px;
  /*animation: shadow  50ms infinite linear;*/
}
.error_text {
  font-size: 16px;
}
.error_header {
  font-size: 21px;
  font-weight: bold;
  margin: 20px 0 10px;
}
.error_text a {
  color: #003DF3;
  text-decoration: none;
  border-bottom: 1px solid #FFFFFF;
  line-height: 18px;
  display: inline-block;
}
.error_code {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 220px;
  font-weight: bold;
  color: #003DF3;
}
@keyframes shadow {
  from {
    background-color: #003DF3;
    color: #FFFFFF;
  }
  to {
    background-color: #FFFFFF;
    color: #003DF3;
  }
}
.issue {
  display: block;
  background: #ecf1f5;
  text-align: center;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 45px;
}
.issue a {
  background-color: #f6af16;
  text-decoration: none;
  display: block;
  width: 185px;
  height: 48px;
  border-radius: 100px;
  text-align: center;
  padding-top: 16px;
  line-height: 14px;
  margin: 35px auto 0;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
/* Ð�Ð¾Ð²Ð°Ñ� Ð²ÐµÑ€Ñ�Ñ‚ÐºÐ° Ð±Ð»Ð¾ÐºÐ¾Ð² Ñ�Ð¾Ð±Ñ‹Ñ‚Ð¸Ð¹ */
#templates_wrapper {
  display: none !important;
}
.index_promotion_wrapper {
  margin-top: 40px;
}
/*.recommend_wrapper {
	margin-top: 41px;
}*/
.recommend_head,
.breadcrumbs_head {
  background-color: #003DF3;
  height: 60px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.45);
  position: relative;
  z-index: 10;
}
.recommend_title,
.breadcrumbs_title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  padding-top: 23px;
  text-transform: uppercase;
  line-height: 20px;
  float: left;
}
.breadcrumbs_title a {
  color: #FFFFFF;
}
.breadcrumbs_title:after {
  background: url(../images/svg/caron-white-icon.svg);
  transform: rotate(30deg);
}
.recommend_arrows {
  float: right;
}
.recommend_arrow_left {
  float: left;
  background: #003DF3 url(../images/arr_white_prev@2x.png) center no-repeat;
  width: 60px;
  height: 60px;
  background-size: 17px;
  cursor: pointer;
}
.recommend_arrow_right {
  float: right;
  background: #003DF3 url(../images/arr_white_next@2x.png) center no-repeat;
  width: 60px;
  height: 60px;
  background-size: 17px;
  cursor: pointer;
}
.recommend_arrow_left.disabled,
.recommend_arrow_right.disabled {
  opacity: 0.3;
  cursor: default;
}
.recommend_arrow_left.disabled:hover,
.recommend_arrow_right.disabled:hover {
  background-color: #003DF3;
}
.recommend_arrow_left:hover,
.recommend_arrow_right:hover {
  background-color: #2727ff;
}
.recommend_content {
  width: 100%;
  height: 340px;
  overflow: hidden;
  background-color: #ecf1f5;
  /*box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);*/
}
.recommend_inner {
  width: 99999px;
  height: 100%;
  transition: all 400ms ease;
}
.recommend_block {
  width: 321px;
  height: 100%;
  float: left;
  border-right: 1px solid #edf0f1;
  transition: all 400ms ease;
  background: #FFFFFF;
}
.index_recommend .recommend_block {
  border-bottom: 1px solid #e0e9ef;
}
.recommend_block.moved {
  transform: translateX(1400px);
}
.recommend_block.moved_back {
  transform: translateX(-1400px);
}
.recommend_image {
  margin-left: -1px;
  height: 181px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: block;
}
.recommend_image .image_shadow {
  height: 60%;
  margin-left: 0;
  background: linear-gradient(to bottom, rgba(17, 17, 19, 0.68), rgba(67, 67, 72, 0));
}
.recommend_date {
  position: absolute;
  color: #FFFFFF;
  padding: 15px 22px;
  font-size: 15px;
  top: 0;
  left: 0;
  text-decoration: none;
}
.recommend_block .events_cat,
.recommend_block_title {
  padding: 0 29px;
}
.recommend_block .events_cat {
  margin-top: 22px;
}
.recommend_block_title {
  margin-top: 10px;
  font-size: 16px;
  color: #232325;
  line-height: 1.38;
  text-decoration: none;
  display: block;
  max-height: 110px;
  overflow: hidden;
}
.recommend_block_title:hover {
  color: #898D91;
}
/* Breadcrumbs */
.inner_pages_breadcrumbs {
  flex: 0 0 100%;
  font-size: 13px;
  color: #B4BAC1;
  border-top: 1px solid #E5E5E5;
  padding-top: 12px;
  margin-bottom: 50px;
}
.inner_pages_breadcrumbs a {
  color: #232325;
}
.inner_pages_breadcrumbs a:hover {
  color: #898D91;
  transition: color .2s;
}
.inner_pages_breadcrumbs .breadcrumbs_split {
  width: 4px;
  height: 7px;
  background: url(../images/breadcrumbs_split.svg);
  display: inline-block;
  margin: 0 12px;
  vertical-align: middle;
}
/**/
.article_content_bg {
  background: #FFFFFF;
  margin-top: 22px;
  margin-bottom: -125px;
  padding-bottom: 125px;
}
.content_bg {
  background: #ecf1f5;
  padding-bottom: 60px;
}
.events_content_bg {
  background: #ecf1f5;
  margin-bottom: -125px;
  padding-bottom: 125px;
}
.events_black {
  height: 61px;
  background-color: #141312;
  color: #FFFFFF;
}
.events_black .content_big,
.events_date,
.news_blue {
  height: 100%;
}
.events_date {
  float: left;
  position: relative;
  z-index: 3;
}
.event-date__day-of-the-month {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
}
small.event-date_year {
  font-size: 12px;
  font-weight: bold;
}
.event-date_weekday {
  font-size: 12px;
  font-weight: 300;
  color: #B4BAC1;
  text-transform: lowercase;
}
/*.events_date_btn {
	float: left;
	height: 100%;
	padding: 23px 25px;
	cursor: pointer;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 20px;
	position: relative;
}
.events_today_btn_decoration {
	z-index: 1;
	display: block;
	margin-top: -12px;
	margin-left: 6px;
	width: 100px;
	height: 13px;
	background-color: @color-main;
}*/
/*.events_cal_btn{
	float: left;
	background: transparent url(../images/svg/calendar-white.svg) no-repeat left center;
	background-size: 28px 32px;
	padding-left: 50px;
}
.events_cal_btn.active input, .events_cal_btn.active .clear_events_date {
	display: block;
}
.events_cal_btn.active span, .events_cal_btn input, .events_cal_btn .clear_events_date {
	display: none;
}*/
/*.events_date_btn.active {
	background-color: #43403d;
}*/
.events_subscribe {
  float: right;
  text-align: right;
  margin-top: 15px;
  margin-right: 55px;
}
/*.subscribe_btn {
	position: absolute;
	top: 12px;
	right: 0;
	width: 38px;
	height: 38px;
	border-radius: 15px;
	background: @color-main url(../images/svg/subscribe.svg) no-repeat center;
}*/
.events_subscribe .open_popup {
  font-size: 16px;
  color: #939699;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
}
.open_popup:after {
  width: 21px;
  height: 21px;
  /*background: url(../images/svg/eye.svg);
	background-size: cover;*/
  background-color: #003DF3;
  border-radius: 30px;
}
.events_subscribe small {
  font-size: 12px;
  color: #939699;
}
/*.events_filters {
	height: 61px;
	border-bottom: 1px solid #d5dde3;
	padding-bottom: 20px;
	padding-top: 19px;
}
.events_filters label.checkbox {
	top: -1px;
	position: relative;
}
label.checkbox {
	font-size: 12px;
	font-weight: bold;
	color: #000;
	margin-left: 0;
	margin-right: 30px;
	display: block;
	float: left;
}
label.checkbox .icheckbox_minimal {
	margin-right: 0;
	margin-left: 8px;
}*/
.events-filters__select .ui-selectmenu-button,
.events_filters .ui-selectmenu-button {
  margin-right: 30px;
  /*vertical-align: bottom;*/
  display: inline-block;
  position: relative;
  /*float: left;
	display: block;
	line-height: 58px;*/
  width: auto !important;
  border: none;
  background: none;
}
.events_date_self {
  margin-top: 42px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}
.event_wrapper {
  position: relative;
  background: #FFFFFF;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}
.event_content {
  position: relative;
  top: 24px;
  background: #FFFFFF;
  padding: 46px 70px 0 76px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
  margin-bottom: 0;
}
.page_content {
  position: relative;
  top: 0;
  background: #FFFFFF;
  padding: 46px 70px 70px 76px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
  margin-bottom: 0;
}
.page_about {
  position: relative;
  /*top: 30px;*/
  background: #FFFFFF;
  padding: 70px 112px 70px 112px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
  /*margin-bottom: 30px;*/
}
.event_description {
  padding: 50px 160px 0;
}
.event_description p {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  font-weight: 200;
  line-height: 27px;
}
.inner_quote {
  position: relative;
  padding: 20px 0 30px 130px;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.13;
}
.inner_quote p {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}
.inner_quote:before {
  content: ' ';
  position: absolute;
  background-image: url(../images/svg/quote-icon.svg);
  background-size: cover;
  width: 70px;
  height: 44px;
  margin-left: -130px;
}
.events_image {
  width: 300px;
  height: 170px;
  position: relative;
  background-size: cover;
  background-position: center;
  float: left;
}
.events_image a:hover {
  background: #0000f3;
}
.events_flag {
  position: absolute;
  right: 21px;
  bottom: 15px;
  width: 64px;
}
.event_by_date .events_about {
  position: relative;
  float: right;
  width: 730px;
  margin-right: 0;
}
.events_about {
  position: relative;
  float: right;
  /*position: relative;
	float: left;
	width: 730px;
	margin-right: 20px;*/
}
.events_tag {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  display: inline;
  font-size: 12px;
  font-weight: normal;
  color: #bec1c3;
  text-transform: lowercase;
}
.events_type {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  text-decoration: none;
  font-size: 12px;
}
.events_lang {
  font-size: 14px;
  font-weight: 200;
  color: #FFFFFF;
  padding: 6px 13px;
  background: #fa880e;
  position: relative;
  float: right;
}
.events_title {
  width: 550px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 25px;
  min-height: 85px;
  color: inherit;
  text-decoration: none;
  display: block;
}
.events_info {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  color: #050505;
  width: 100%;
  margin-top: 9px;
  padding-top: 15px;
  border-top: 1px solid #ebeff2;
  line-height: 1;
  position: relative;
  top: 3px;
}
.events_time {
  float: left;
  padding-left: 31px;
  position: relative;
}
.events_place {
  float: left;
  padding-left: 23px;
  position: relative;
  margin-left: 26px;
}
.events_views {
  color: #b7bbbf;
  float: right;
  padding-right: 31px;
  position: relative;
}
.events_time:before,
.events_place:before,
.events_views:before {
  position: absolute;
  content: ' ';
  display: block;
}
.events_time:before {
  background: url(../images/svg/clock.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  top: -4px;
  left: 0;
}
.events_place:before {
  background: url(../images/svg/place.svg) no-repeat 0 0;
  width: 15px;
  height: 21px;
  top: -5px;
  left: 0;
}
.events_views:before {
  background: url(../images/svg/eye-grey.svg) no-repeat 0 0;
  width: 21px;
  height: 15px;
  top: 0;
  right: 0;
}
/*input#events_date_start {
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 20px;
	background: transparent;
	color: @white;
	border: none;
	outline: none;
	padding: 0;
	box-shadow: none;
	margin-top: -4px;
	width: 90px;
}*/
.news_content_wrapper {
  width: 697px;
  float: right;
}
.news_content_wrapper h1 {
  margin-top: 0;
}
.news_content_wrapper .filters_wrapper {
  padding-bottom: 20px;
}
.news_content_wrapper .filters_wrapper .ui-selectmenu-button {
  width: 110px !important;
}
.news_content_wrapper .ui-selectmenu-button,
.news_content_wrapper .ui-state-hover.ui-selectmenu-button,
.news_content_wrapper .ui-state-focus.ui-selectmenu-button {
  border: 1px solid #dee5ec;
}
.event_description.event_content.news_content {
  border: none;
  padding-left: 0;
}
.event_description.event_content.news_content .fotorama_counter,
.event_description.event_content.news_content .event_socials {
  margin-right: -46px;
}
.event_about.event_content.news_content_outer {
  /*border-bottom: 1px solid #dee5ec;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.news_date_wrapper {
  float: left;
  padding-right: 30px;
  position: relative;
}
#date_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.news_date_wrapper #date_input {
  width: 140px;
  height: 25px;
}
.news_date_wrapper.active #date_input {
  padding-right: 29px;
}
.news_date_wrapper .remove_date {
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
  display: none;
  background-image: url(../images/svg/exit.svg);
  width: 18px;
  height: 18px;
  background-size: cover;
  z-index: 5;
}
#filter_department {
  width: 105px;
}
#date_output {
  /*display: none;*/
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
}
#date_input {
  width: 140px;
  text-align: right;
  background: transparent url(../images/svg/calendar.svg) no-repeat 5px 0;
  font-size: 14px;
  font-weight: bold;
  color: #232325 !important;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
}
input#date_input::placeholder {
  color: #232325;
}
.news_wrapper .events_block {
  width: 100%;
  border-top: 1px solid #d5dde3;
  padding: 45px 0 0;
  border-bottom: none;
  margin-bottom: 45px;
}
.news_wrapper .events_image {
  width: 250px;
  height: 140px;
}
.news_wrapper .events_about {
  width: 416px;
}
.event_content .events_about {
  width: 50%;
  max-width: 450px;
}
.news_wrapper .events_title {
  width: 100%;
}
#nothing_found {
  font-size: 18px;
  display: none;
  margin-top: 50px;
}
#picker-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.datepicker.dropdown-menu {
  position: fixed;
  z-index: 9999999 !important;
}
.datepicker:before,
.datepicker:after {
  display: none !important;
}
.datepicker {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  font-size: 18px;
  border-radius: 0 !important;
  padding: 0 0 30px;
  border: none !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3) !important;
}
.datepicker * {
  border-radius: 0 !important;
  border: none !important;
}
.datepicker table tr td,
.datepicker table tr th {
  width: 58px;
  height: 44px;
  border-radius: 0;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
}
.datepicker table tr td:nth-child(1),
.datepicker table tr th:nth-child(1) {
  padding-left: 30px;
}
.datepicker table tr td:last-child,
.datepicker table tr th:last-child {
  padding-right: 30px;
}
.datepicker table {
  border-collapse: collapse;
}
.datepicker table tr:nth-child(1) th {
  background-color: #003DF3;
  border-radius: 0;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  color: #FFFFFF;
  height: 150px;
  font-size: 20px;
  font-weight: normal;
  /*text-transform: uppercase;*/
}
.datepicker table tr th.prev,
.datepicker table tr th.next {
  padding-left: 30px;
  padding-right: 29px;
}
/*.datepicker table tr th.prev {
	float: right;
	background: url(../images/arr_white_next@2x.png);
	width: 17px;
	height: 28px;
	background-size: cover;
	margin-left: 44px;
	cursor: pointer;
}*/
.datepicker table tr:nth-child(1) th:hover {
  background-color: rgba(0, 0, 243, 0.9);
}
#fixed_date_wrapper {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 8500;
  line-height: 1;
  font-size: 17px;
  color: #FFFFFF;
  padding: 11px 0;
  /*border-top: 1px solid #ecf1f5;*/
  box-shadow: 0 2px 5px 0 rgba(20, 19, 18, 0.15);
  transition: all 300ms ease;
  transform: translateY(-200px);
}
#fixed_date_wrapper.active {
  transform: translateY(0);
}
.breadcrumbs_line {
  width: 100%;
  height: 54px;
  color: #000;
  padding-top: 20px;
}
.breadcrumb_parent,
.breadcrumb_current {
  font-size: 18px;
  line-height: 1;
  display: inline-block;
  position: relative;
}
.breadcrumb_parent {
  margin-right: 10px;
  padding-right: 27px;
}
a.breadcrumb_parent {
  color: #000;
}
.breadcrumb_parent:after {
  content: ' ';
  display: block;
  background: url(../images/svg/caron-icon.svg) no-repeat;
  width: 6px;
  height: 8px;
  background-size: contain;
  position: absolute;
  top: 5px;
  right: 0;
  transform: rotate(-90deg);
}
.breadcrumb_current {
  padding-right: 30px;
  cursor: pointer;
  font-weight: bold;
  /*max-width: 690px;
	white-space: pre;
	text-overflow: ellipsis;
	overflow: hidden;*/
}
.breadcrumb_current:after {
  content: ' ';
  display: block;
  background: #FFFFFF url(../images/svg/caron-icon.svg) no-repeat center;
  background-size: 9px;
  width: 6px;
  height: 6px;
  padding: 6px;
  border-radius: 12px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
}
.breadcrumb_current.department_current {
  cursor: default;
}
.breadcrumb_current.department_current:after {
  display: none;
}
.breadcrumb_links {
  display: none;
  position: absolute;
  height: auto;
  z-index: 999;
  overflow: auto;
  background: #FFFFFF;
  margin-top: 10px;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
  width: 282px;
  max-height: 342px;
  right: 0;
  text-transform: lowercase;
}
.breadcrumb_links a {
  cursor: pointer;
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: #717172;
  border-bottom: 1px solid #edf0f1;
  display: block;
}
.breadcrumb_links a:hover {
  opacity: 1;
  color: #252527;
  background-color: #edeff1;
}
@media print {
  .scheme_header {
    margin-top: 0;
  }
  .section_info.active {
    position: relative;
    padding-bottom: 40px;
  }
  .section_info_text {
    margin-top: 15px;
  }
  .section_info_print,
  .section_switch_block,
  .etage_section_info_btn.active {
    display: none;
  }
  .sections_switcher {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .etage_scheme_wrapper {
    float: none;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .popup_map_image {
    text-align: center;
    margin-top: 30px;
    float: none;
    width: 100%;
  }
}
/* Addition 10.7.2017 */
.common-button {
  color: #FFFFFF;
}
/* Parallax */
.umlaut_img {
  margin: 0 auto;
  width: 80px;
  padding-top: 80px;
}
.vf_search_wrapper {
  z-index: 11;
  position: relative;
  float: right;
  width: 400px;
}
/*.vf_search_wrapper {
	position: absolute;
	z-index: 9;
	right: 0;
	width: 355px;
}*/
.search_switch {
  cursor: pointer;
  font-size: 14px;
  color: #232325;
}
.vf_search_form,
.catalogue_search_form {
  top: 6px;
  height: 47px;
  position: relative;
  background-color: #f5f6f7;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}
.catalogue_search_form {
  box-shadow: 0 6px 24px 0 rgba(30, 30, 130, 0.6);
}
.search_switch:after {
  content: '';
  width: 7px;
  background: url(../images/svg/up_down.svg) no-repeat center;
  display: inline-block;
  height: 10px;
  position: relative;
  margin-left: 4px;
}
.full-index {
  cursor: pointer;
  float: right;
  font-size: 13px;
  font-weight: 600;
  color: #232325;
  margin-top: 2px;
}
.full-index:after {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #E5E5E5 url(../images/svg/arrow-back.svg) center no-repeat;
  background-size: 10px;
  border-radius: 4px;
  transform: rotate(180deg);
  margin-bottom: -3px;
  margin-left: 5px;
}
.vf_search_wrapper img,
.catalogue_search_wrapper img {
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  cursor: pointer;
  z-index: 3;
  padding: 15px;
  box-sizing: content-box;
  /*transform: rotate(270deg);
    animation: rotation 0.3s linear;*/
}
.vf_search_wrapper img.active,
.catalogue_search_wrapper img.active {
  /*transform: rotate(0deg);
	animation: rotation 0.3s linear;*/
}
.catalogue_search_wrapper img:hover,
.vf_search_wrapper img:hover {
  opacity: .9;
}
/*@keyframes rotation {
	from {
		 -webkit-transform: rotate(0deg);
	}
	to {
		 -webkit-transform: rotate(180deg);
	}
}*/
.vf_search_select {
  display: none;
  position: absolute;
  height: auto;
  z-index: 999;
  overflow: hidden;
  width: 190px;
  left: 211px;
  /*padding-left: 18px;*/
  background: #FFFFFF;
  margin-top: 7px;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 183, 0.14);
}
/* 16.11 */
#index_search_wrapper {
  position: relative;
  padding: 55px 0 20px;
  z-index: 9000;
}
input#search,
input#index_search {
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0 0 0 20px;
  outline: none;
  box-shadow: none;
}
input#search::-webkit-input-placeholder,
input#index_search::-webkit-input-placeholder {
  color: #9b9b9b;
}
/*input#search::-webkit-input-placeholder,
input#search::-moz-placeholder,
input#search:-moz-placeholder,
input#search:-ms-input-placeholder {
	font-family: @font-family--secondary;
	font-size: 14px;
	color: rgba(66,66,66,0.3);
}*/
.vf_search_option,
.index_search_option {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  cursor: pointer;
  padding: 20px;
  font-size: 14px;
  color: #717172;
  border-bottom: 1px solid #edf0f1;
}
.vf_search_option:hover {
  color: #232325;
  background-color: #edeff1;
}
.advanced_search {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #003DF3;
  padding: 20px;
}
.catalogue_search_background {
  position: relative;
  padding: 20%;
  border-radius: 2px;
  background-color: rgba(16, 2, 96, 0.57);
}
.catalogue_search_image {
  width: 100%;
  mix-blend-mode: multiply;
}
/* News */
.news_block {
  float: left;
  width: 384px;
  height: 412px;
  margin: 23px 23px 0 0;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
}
.news_block .news_image {
  z-index: 3;
}
.news_block .event-tag {
  z-index: 4;
}
@media screen and (min-width: 1281px) {
  .news_block:nth-child(3n+3) {
    margin-right: 0;
  }
}
.news_image {
  position: relative;
  float: left;
  width: 384px;
  height: 217px;
  background-size: cover;
  /*background-position: center;*/
  border-radius: 5px 5px 0 0;
  margin-bottom: 24px;
}
.news_about {
  position: relative;
  padding: 24px 24px 35px;
}
.news_type {
  font-size: 12px;
  text-decoration: none;
}
.news_cat {
  font-size: 12px;
  color: #bec1c3;
}
.news_about a.event-type {
  float: left;
}
.news_tag {
  float: right;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #B4BAC1;
}
.special_wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
#news_special.special_wrapper {
  top: 196px;
  left: 24px;
  right: auto;
}
.news_lang {
  font-size: 14px;
  font-weight: 200;
  color: #FFFFFF;
  padding: 8px 13px;
  background: #fa880e;
  position: relative;
  float: left;
}
.news_title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 16px;
  /*min-height: 85px;*/
  color: #232325;
  text-decoration: none;
  display: block;
}
/**/
.article_desc {
  text-align: center;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  /*margin: 0 auto;*/
}
img.contacts_image {
  width: 100%;
  position: relative;
  /*top: 5px;*/
}
.spot:before {
  content: '';
  display: block;
  background-color: #05058A;
  opacity: 0.58;
  width: 100%;
  height: 100%;
}
/*.navbar {
	height: 33px;
	color: @white;
	background-color: @gray--darken;
	font-family: 'Open Sans', sans-serif, Arial, Helvetica;
	font-size: 12px;
	font-weight: 300;
	line-height: 33px;
}
.navbar_left {
	float: left;
}
.navbar_left a {
	display: block;
	float: left;
	margin-left: 5px;
}
.navbar_right a:hover {
	opacity: 1;
	color: @white;
}
.navbar_left span {
	margin-right: 20px;
	font-weight: 600;
}
a.vk__element {
	width: 33px;
	height: 33px;
	background: url(../images/svg/vk-icon.svg) no-repeat center;
	background-size: 20px;
}
a.fb__element {
	width: 23px;
	height: 33px;
	background: url(../images/svg/fb-icon.svg) no-repeat center;
	background-size: 15px;
}
a.insta__element {
	width: 33px;
	height: 33px;
	background: url(../images/svg/in-icon.svg) no-repeat center;
	background-size: 15px;
}
a.youtube__element {
	width: 33px;
	height: 33px;
	background: url(../images/svg/yt-icon.svg) no-repeat center;
	background-size: 20px;
}
.navbar_right {
	float: right;
	position: relative;
}
.navbar_right a {
	color: #ababab;
	text-decoration: underline;
}
.navbar_right a:before {
	content: '';
	position: absolute;
	display: block;
	background: url(../images/svg/academy.svg) no-repeat 0 0;
	width: 20px;
	height: 20px;
	top: 10px;
	left: -40px;
}
*/
/**/
/* Parallax */
img.news_left_image {
  position: absolute;
  width: 157px;
  z-index: 0;
  bottom: 50px;
  left: -72px;
}
img.news_right_image {
  position: absolute;
  width: 203px;
  z-index: 0;
  top: -17px;
  right: -78px;
}
/**/
.label {
  font-size: 15px;
  font-weight: 300;
  color: #B4BAC1;
}
.large_text {
  font-size: 27px;
  font-weight: 500;
  line-height: 37px;
  color: #232325;
  padding: 25px 0 15px;
}
.contact_page_text_wrapper {
  margin: 0 auto;
}
.contact_page_text {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  /*margin-bottom: 60px;*/
  color: #B4BAC1;
  font-size: 15px;
}
.contact_page_text a {
  color: #003DF3;
  font-size: 14px;
}
.contact_page_text a:hover {
  color: #8300f3;
}
.subway_label {
  display: inline-block;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 14px;
  font-weight: 600;
  color: #474f5c;
  background-color: #E5E5E5;
  border-radius: 3px;
  padding: 7px 11px;
  margin-right: 5px;
}
.parking_note {
  float: right;
  font-size: 14px;
  color: #a1a5a9;
  text-align: right;
  margin-top: -7px;
}
.flex_wrapper {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 0;
  -webkit-flex-grow: 0;
}
.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.flex_row {
  border-bottom: 2px solid #e0e0e0;
  border-collapse: collapse;
  padding-top: 5px;
}
.flex_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
  flex-grow: 1;
}
.flex_text:last-child {
  text-align: right;
}
.contact_wrapper {
  /*position: absolute;*/
  width: 100%;
  height: 354px;
  background-image: url(../images/rudomino.jpg);
}
img.contacts_image {
  width: 100%;
  position: relative;
}
img.contacts_image:before {
  background: #003DF3;
  opacity: 57%;
}
.contact_text_wrapper {
  position: relative;
  padding: 30px 30px 30px 112px;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 18px;
}
.contact_text_header {
  float: left;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 29px;
  font-weight: bold;
}
.contact_text_subheader {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.contact_text_wrapper span {
  line-height: 2.3;
  font-weight: 300;
}
.contact_text_wrapper span:nth-last-of-type(3) {
  margin-left: 80px;
}
.contact_text_wrapper b {
  padding-right: 30px;
}
.contact_btn {
  cursor: pointer;
  float: right;
  font-size: 14px;
  background: #003DF3;
  color: #FFFFFF;
  text-decoration: none;
  padding: 14px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.contact_btn:hover {
  opacity: 1;
  background: #2f2fff;
}
.note {
  float: right;
  position: relative;
  width: 225px;
  font-size: 15px;
  font-weight: 300;
  margin-top: -70px;
}
.note:before {
  content: '';
  width: 34px;
  height: 34px;
  background: url(../images/svg/exclamative.svg);
  position: absolute;
  left: -60px;
}
.contact_table {
  width: 100%;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  margin-bottom: 60px;
}
.contact_table .contact_row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}
.contact_table .contact_row .contact_cell {
  width: calc(100% / 4);
  padding: 1em 0;
  border-bottom: 1px solid #EDF0F1;
}
.contact_table .contact_row .contact_cell:first-of-type {
  width: calc(100% / 2);
}
.contact_table .contact_row .contact_cell a {
  color: #003DF3;
}
.contact_table .contact_row .contact_cell a:hover,
.contact_table .contact_row .contact_cell a :focus {
  color: #7900f3;
}
.contact_table .contact_row .contact_cell + .contact_cell {
  padding-left: 1em;
}
@media screen and (max-width: 1100px) {
  .contact_table {
    margin-bottom: 40px;
  }
  .contact_table .contact_row .contact_cell {
    flex: 100%;
  }
  .contact_table .contact_row .contact_cell:not(:last-child) {
    border-bottom: none;
    padding: 0;
  }
  .contact_table .contact_row .contact_cell + .contact_cell {
    padding-top: 14px;
    padding-left: 0;
  }
  .contact_table .contact_row + .contact_row {
    margin-top: 1em;
  }
}
.table {
  display: table;
  table-layout: fixed;
}
.tr {
  display: table-row;
}
.td {
  display: table-cell;
}
.working_hours .td {
  line-height: 42px;
}
/*.working_hours.table {
	width: 100%;
	max-width: 650px;
	color: @white;
	font-size: 18px;
}
.working_hours.table>.tr>.td {
	width: 23%;
	padding: 10px 0;
}
.working_hours.table>.tr>.td:last-child {
	width: 87%;
}*/
.index_collections_wrapper {
  margin-bottom: 80px;
  background: linear-gradient(to bottom, #f6f6fe, #f1f1fe);
}
.index_departments_wrapper {
  position: relative;
  margin-bottom: 160px;
}
.index_promotion_wrapper .content-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.index_header {
  position: relative;
  display: inline-block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 35px;
  color: #232325;
  text-transform: uppercase;
}
.index_header:hover,
.index_header :focus {
  opacity: .9;
  transition: opacity .2s;
}
#index_banners_wrapper {
  position: relative;
  flex: 1 0 auto;
  max-width: 794px;
  /*float: left;
	width: 794px;*/
  overflow: hidden;
  margin-right: 22px;
}
#index_banners_wrapper .index_header {
  padding-bottom: 30px;
}
#index_show_pins {
  float: right;
  text-align: right;
  padding: 10px 0;
}
.index_show_controls {
  float: left;
  padding: 10px 0;
}
.index_show_controls .index_show_left,
.index_show_controls .index_show_right {
  cursor: pointer;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #f6f6f6 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 24px;
  border-radius: 12px;
}
.index_show_controls .index_show_left:hover,
.index_show_controls .index_show_right:hover,
.index_show_controls .index_show_left :focus,
.index_show_controls .index_show_right :focus {
  background-color: #E5E5E5;
  transition: background-color 0.2s;
}
.index_show_controls .index_show_right {
  transform: rotate(180deg);
}
#index_banners_inner {
  width: 9999px;
  transform: translateX(0px);
  transition: all 300ms ease;
}
#index_banners_inner a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  width: 794px;
  height: 380px;
  position: relative;
}
#index_banners_inner div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
#index_banners_inner a:hover {
  filter: saturate(150%);
}
.block_listing {
  width: 200px;
  height: 240px;
  float: left;
  margin-right: 60px;
  margin-bottom: 60px;
  color: inherit;
}
.block_listing__image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  display: block;
}
.block_listing__title {
  display: block;
  width: 100%;
  margin-top: 8px;
  font-weight: 700;
}
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}
.slides,
.flex-control-nav,
.flex-direction-nav {
  float: left;
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
}
.flexslider a img {
  outline: none;
  border: none;
}
.flexslider {
  margin: 0;
  padding: 0;
}
/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
/* Clearfix for the .slides element */
.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .slides {
  display: block;
}
* html .slides {
  height: 1%;
}
/* Theme Styles */
.flexslider {
  position: relative;
  zoom: 1;
  background: #FFFFFF;
}
/* Edit it if you want */
.flex-container {
  min-width: 150px;
  max-width: 794px;
}
@media screen and (max-width: 1200px) {
  .flex-container {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .flex-container {
    width: 100%;
  }
}
.flexslider .slides {
  zoom: 1;
}
.flex-nav-prev {
  margin-right: 5px;
}
.flex-nav-prev,
.flex-nav-next {
  display: inline-block;
}
/* Direction Nav */
.flex-direction-nav a {
  cursor: pointer;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #f6f6f6 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 24px;
  border-radius: 12px;
  text-indent: -9999px;
}
.flex-direction-nav a:hover,
.flex-direction-nav a :focus {
  background-color: #E5E5E5;
  transition: background-color 0.2s;
}
.flex-direction-nav .flex-next {
  transform: rotate(180deg);
}
/* Control Nav */
.flexslider .flex-control-nav {
  float: right;
  text-align: right;
  margin: 20px 0 0;
}
.flex-control-nav li {
  display: inline-block;
  zoom: 1;
}
.flex-control-paging li a {
  display: block;
  cursor: pointer;
  text-indent: 300px;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background-color: #E5E5E5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.flex-control-paging li a.flex-active {
  background-color: #232323;
}
/* Captions */
.flexslider .slides p {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 5px;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.9);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
/** Accordion **/
.accordion {
  margin: 50px 0 30px;
}
.accordion dt {
  padding: 20px 65px;
  background: #f5f6f7 url(../images/svg/plus.svg) no-repeat 20px center;
  background-size: 24px;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  margin-top: 15px;
}
.accordion dt.is-opened {
  transition: all .2s;
  background: #f5f6f7 url(../images/svg/minus.svg) no-repeat 20px center;
  border-radius: 2px 2px 0 0;
}
.accordion dt span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 1.3;
  color: #232325;
}
.accordion dd {
  display: none;
  margin-left: 0;
  border: 1px solid #E5E5E5;
  border-top: 0;
  background-color: #FFFFFF;
}
.accordion dd p {
  font-size: 18px;
  line-height: 1.8;
  text-align: left;
  width: 80%;
  margin: 0;
  padding: 20px 25px;
}
.accordion dd p img {
  display: block;
  margin: 20px 0;
  border: 1px solid #E5E5E5;
}
.accordion dd ul {
  padding: 0 20px 0 40px;
}
.accordion dd .dd-row {
  display: flex;
  padding: 11px 30px 11px 20px;
  margin: 0;
  border-bottom: 1px solid #E5E5E5;
}
@media screen and (max-width: 768px) {
  .accordion dd .dd-row {
    padding: 10px;
  }
}
.accordion dd .dd-row .dd-cell,
.accordion dd .dd-row .dd-head {
  font-size: 14px;
  font-weight: 400;
  color: #232325;
}
.accordion dd .dd-row .dd-cell:only-child,
.accordion dd .dd-row .dd-head:only-child {
  font-size: 18px !important;
  line-height: 1.8 !important;
  text-align: left !important;
  width: 80% !important;
}
.accordion dd .dd-row .dd-cell:first-of-type,
.accordion dd .dd-row .dd-head:first-of-type {
  width: 5%;
}
.accordion dd .dd-row .dd-cell:nth-of-type(2),
.accordion dd .dd-row .dd-head:nth-of-type(2) {
  width: 65%;
  padding-left: 10px;
}
.accordion dd .dd-row .dd-cell:nth-of-type(3),
.accordion dd .dd-row .dd-head:nth-of-type(3) {
  width: 20%;
  padding: 0 20px;
}
.accordion dd .dd-row .dd-cell:last-of-type,
.accordion dd .dd-row .dd-head:last-of-type {
  width: 10%;
  text-align: right;
  border-bottom: 0;
}
.accordion dd .dd-row .dd-head {
  color: #B4BAC1;
}
.accordion dd .dd-row:last-of-type {
  border-radius: 0 0 2px 2px;
}
.footnote .footnote__text {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.36;
  color: #B4BAC1;
  margin: 0 0 1em;
}
.footnote .footnote__link {
  color: #003DF3;
}
.footnote .footnote__link:hover,
.footnote .footnote__link :focus {
  color: #7900f3;
  transition: color .2s;
}
.facilities_right {
  width: 384px;
  margin-top: 81px;
  margin-left: 30px;
}
.facilities_banner a.facilities_banner__image {
  display: block;
  width: 384px;
  height: 252px;
  background: url(../images/pearson-learning-center.png) no-repeat center;
  background-size: contain;
  margin-bottom: 20px;
}
.facilities_banner a.facilities_banner__image:hover,
.facilities_banner a.facilities_banner__image :focus {
  opacity: .9;
  transition: opacity .2s;
}
.facilities_banner a.facilities_banner__description {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #232325;
}
.facilities_banner a.facilities_banner__description:hover,
.facilities_banner a.facilities_banner__description :focus {
  opacity: .9;
  transition: opacity .2s;
}
.block-list__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.block-list__item {
  position: relative;
  width: calc(100% / 4 - 20px);
  height: 284px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
  padding: 25px 30px;
  margin-bottom: 20px;
}
.block-list__item + .block-list__item {
  margin-left: 20px;
}
.block-list__item.single {
  max-width: 284px;
}
.block-list__item.double {
  width: calc(100% / 2 - 20px);
}
@media screen and (max-width: 1100px) {
  main {
    width: 100%;
  }
  .block-list__item {
    width: calc(100% / 2 - 20px);
  }
  .block-list__item.single {
    max-width: 50%;
  }
  .block-list__item.double {
    width: 100%;
    order: -1;
  }
}
@media screen and (max-width: 768px) {
  .block-list__item {
    width: 100%;
    height: auto;
  }
  .block-list__item + .block-list__item {
    margin-left: 0;
  }
  .block-list__item.single {
    max-width: 100%;
  }
}
/* Dashboard */
.dashboard-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-bottom: 80px;
}
.dashboard-item {
  position: relative;
  border-radius: 5px;
  box-shadow: 0 2px 14px rgba(0, 0, 243, 0.11);
  padding: 35px 30px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.dashboard-item .item-title {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.dashboard-item .item-title ~ a {
  display: block;
  font-size: 15px;
  letter-spacing: -0.2px;
  line-height: 28px;
}
.dashboard-item .item-title ~ a:hover,
.dashboard-item .item-title ~ a :focus {
  color: #B4BAC1;
  transition: color .2s;
}
.dashboard-item.info {
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 20px);
  height: 284px;
}
.dashboard-item.info .address {
  font-size: 27px;
  font-weight: 400;
  line-height: 37px;
  color: #232325;
}
.dashboard-item.info .address span {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  color: #B4BAC1;
}
.dashboard-item.info a.map {
  position: absolute;
  right: 25px;
  bottom: 30px;
  width: 170px;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #003DF3;
  text-align: center;
  background-color: #E5E5E5;
  border-radius: 15px;
}
.dashboard-item.info a.map:hover,
.dashboard-item.info a.map :focus {
  color: #7900f3;
  transition: color .2s;
}
.dashboard-item.info a {
  z-index: 2;
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 28px;
  color: #003DF3;
}
.dashboard-item.info a:first-of-type {
  margin-top: auto;
}
.dashboard-item.info a:hover,
.dashboard-item.info a :focus {
  color: #7900f3;
  transition: color .2s;
}
.dashboard-item.info a.underlink {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dashboard-item.visitors {
  width: calc(100% / 4 - 20px);
  height: 284px;
  background: url(../images/readers.jpg) no-repeat center;
  background-size: cover;
  background-blend-mode: multiply;
}
.dashboard-item.visitors .item-title {
  color: #FFFFFF;
}
.dashboard-item.visitors .item-title ~ a {
  font-weight: 700;
  color: #FFFFFF;
}
.dashboard-item.visitors .item-title ~ a:hover,
.dashboard-item.visitors .item-title ~ a :focus {
  color: #B4BAC1;
  transition: color .2s;
}
.dashboard-item.digest {
  width: calc(100% / 4 - 20px);
  height: 284px;
}
.dashboard-item.digest .item-title {
  color: #232325;
}
.dashboard-item.digest .item-title:hover,
.dashboard-item.digest .item-title :focus {
  color: #898D91;
  transition: color .2s;
}
.dashboard-item.digest .item-title ~ a {
  font-weight: 400;
  color: #003DF3;
}
.dashboard-item.digest .item-title ~ a:hover,
.dashboard-item.digest .item-title ~ a :focus {
  color: #7900f3;
  transition: color .2s;
}
.dashboard-item.quick {
  width: 100%;
  height: auto;
  background: #FFFFFF url(../images/svg/rudomino_m_i.svg) no-repeat 100% 100%;
  background-size: contain;
  padding: 30px 35% 35px 30px;
}
.dashboard-item.quick span {
  position: absolute;
  right: 35%;
  bottom: 35px;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #B4BAC1;
}
.dashboard-item.quick ul {
  /*display: inline-block;*/
  margin: 0;
  padding: 0;
  columns: 2 auto;
  -webkit-columns: 2 auto;
  -moz-columns: 2 auto;
  column-gap: 50px;
  -webkit-column-gap: 50px;
  -moz-columns-gap: 50px;
}
.dashboard-item.quick ul li {
  list-style: none;
}
.dashboard-item.quick ul li a {
  position: relative;
  display: inline-block;
  padding-bottom: 30px;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.47px;
  line-height: 28px;
  text-transform: uppercase;
  color: #232325;
  margin-left: 50px;
}
.dashboard-item.quick ul li a:before {
  content: "";
  position: absolute;
  width: 28px;
  height: 10px;
  top: 10px;
  left: -45px;
  background: url(../images/svg/tilde-icon.svg);
  background-size: cover;
}
.dashboard-item.quick ul li a:hover {
  color: #898D91;
  transition: all .2s;
}
.dashboard-item.quick ul li + li {
  margin-top: 0;
}
@media screen and (max-width: 1100px) {
  .dashboard-item.info {
    width: 100%;
    height: 284px;
  }
  .dashboard-item.visitors {
    width: calc(100% / 2 - 20px);
    height: 284px;
  }
  .dashboard-item.digest {
    width: calc(100% / 2 - 20px);
    height: 284px;
  }
  .dashboard-item.quick {
    background: #FFFFFF;
    padding: 30px 35px;
  }
  .dashboard-item.quick span {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .dashboard-item.quick ul {
    columns: 1 auto;
    column-gap: 0;
  }
  .dashboard-item.visitors {
    width: 100%;
  }
  .dashboard-item.digest {
    width: 100%;
  }
}
.libfl-structure {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -16px;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .libfl-structure {
    margin-left: 0;
  }
}
.libfl-structure__item {
  cursor: pointer;
  width: calc(100% / 3 - 16px);
  height: auto;
  padding: 10px 15px 15px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}
.libfl-structure__item:hover {
  background-color: #f5f6f7;
  transition: all .3s;
}
@media screen and (max-width: 900px) {
  .libfl-structure__item {
    width: calc(100% / 2 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .libfl-structure__item {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }
}
.libfl-structure__title {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 1.2;
  vertical-align: middle;
  color: #232325;
}
.index-departments {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}
.libfl-centers {
  position: relative;
  width: 100%;
}
.libfl-centers__heading {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -0.6px;
  line-height: 32px;
  color: #232325;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}
.libfl-centers__inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}
.libfl-centers__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 900px) {
  .libfl-centers__list {
    max-width: 400px;
  }
  .libfl-centers__list:last-child {
    margin-left: 60px;
  }
}
.libfl-centers__item {
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
}
.libfl-centers__item:hover .libfl-centers__logo {
  transform: scale(1.1);
  transition: all .3s;
}
.libfl-centers__item:hover .libfl-centers__title {
  color: #6d6d74;
  transition: all .3s;
}
.libfl-centers__link {
  display: flex;
  align-items: center;
  max-height: 70px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.libfl-centers__logo {
  width: 40px;
  height: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  margin-right: 20px;
}
.libfl-centers__title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 20px;
  color: #232325;
  padding: 6px 0;
}
.libfl-centers__decoration {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 163px;
  height: 429px;
  background: url(../images/svg/right-shape.svg) center no-repeat;
  background-size: contain;
}
.partners-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.partners-block a {
  width: calc(33.33333333%);
  margin-bottom: 70px;
  text-align: center;
}
.partners-block a img {
  max-width: 100%;
  max-height: 120px;
}
.partners-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.partners-block a {
  width: calc(33.33333333%);
  margin-bottom: 70px;
  text-align: center;
}
.partners-block a img {
  max-width: 100%;
  max-height: 120px;
}
.partners_column {
  z-index: 2;
  float: right;
  width: 282px;
  margin-top: 70px;
}
.partners_column a.partners_block {
  display: flex;
  flex-direction: column;
  height: 543px;
  border: 8px solid #E5E5E5;
  padding: 30px 35px;
}
.partners_column a.partners_block:hover .partners_block_title {
  color: #898D91;
  transition: all .2s;
}
.partners_column a.partners_block:hover .partners_block_image {
  transform: rotate(3deg);
  transition: all .3s;
}
.partners_column a.partners_block:hover .fall_through_link {
  color: #7900f3;
  transition: all .2s;
}
.partners_column a.partners_block .partners_block_title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 1.7em;
  font-weight: bold;
  line-height: 1.25;
  text-transform: uppercase;
  color: #232325;
}
.partners_column a.partners_block .partners_block_title:hover {
  color: #898D91;
  transition: color .2s;
}
.partners_column a.partners_block .partners_block_image {
  width: 63px;
  height: 121px;
  background: url(../images/svg/spectacles.svg) no-repeat center;
  background-size: contain;
  margin-top: 40px;
}
.partners_column a.partners_block .fall_through_link {
  margin-top: auto;
  text-align: right;
}
.js-open-special-version {
  width: 30px;
  background: url(../images/svg/eye_icon.svg) center no-repeat;
  background-size: cover;
  position: relative;
}
.js-open-special-version.active:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  top: 25px;
  left: 0;
  transform: rotate(-45deg);
}
.error-code {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 200px;
  font-weight: bold;
  color: #003DF3;
  margin-bottom: 60px;
}
.opening_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 40px;
}
.opening_wrapper .opening_slideshow {
  width: 100%;
  max-width: 700px;
  height: 466px;
}
.opening_wrapper .opening_slideshow .opening_slideshow_image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.opening_wrapper .opening_slideshow .opening_slideshow_image.disabled {
  display: none !important;
}
.opening_wrapper .opening_slideshow .opening_text {
  z-index: 2;
  position: absolute;
  top: calc(50% - 150px);
  right: 0;
  width: 100%;
  max-width: 700px;
  height: auto;
  padding: 40px 45px;
  background-color: #FFFFFF;
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.15);
}
.opening_wrapper .opening_slideshow .opening_text h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.35px;
  color: #232325;
}
.opening_wrapper .opening_slideshow .opening_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #232325;
}
.opening_wrapper .opening_slideshow .opening_text a {
  font-size: 13px;
  font-weight: 600;
  color: #003DF3;
}
.opening_wrapper .opening_slideshow .opening_text a:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #E5E5E5 url(../images/svg/arrow-back.svg) 50% no-repeat;
  background-size: 10px;
  border-radius: 4px;
  transform: rotate(180deg);
  margin-bottom: -3px;
  margin-left: 5px;
}
a.foreign_partners_banner {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto 20px;
  border: 4px solid #003DF3;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.12px;
  line-height: 20px;
  color: #003DF3;
}
a.foreign_partners_banner span {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.06px;
  color: #B4BAC1;
  margin-top: 10px;
}
a.foreign_partners_banner:hover {
  border: 4px solid #7900f3;
  color: #7900f3;
}
/*.pro_block {
	display: block;
	width: 282px;
	height: 655px;
	padding: 25px 30px;
	margin: 10px 0;
	background-color: @white;
	border-radius: 5px;
	box-shadow: 0 2px 14px 0 rgba(0,0,243,.11);

	.pro_block__image {
		width: ~"calc(100% + 60px)";
		height: 197px;
		background: url(../attachments/attachment/middle/5df062d72b9c241c56bdb01c-middle.jpg) no-repeat top center;
		border-radius: 5px 5px 0 0;
		margin: -25px -30px 30px;
	}
	a.pro_block__title {
		display: block;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		color: @gray--darken;
		margin-bottom: 35px;

		&:hover {
			color: @gray--darken;
			transition: color .2s;
		}
		&:focus {
			color: @gray--darken;
			transition: color .2s;
		}
	}
	.pro_block__text {
		font-size: 15px;
		font-weight: 400;
		letter-spacing: -.4px;
		line-height: 19px;
		color: @gray--darken;
	}
	.pro_block__label {
		font-size: 26px;
		font-weight: 700;
		letter-spacing: -.35px;
		line-height: 20px;
		color: @gray--darken;
		margin-top: 70px;
	}
}
 */
.index_visitors_wrapper {
  margin-bottom: 60px;
}
.row > .article-content > .libfl_block {
  margin: 10px 20px 0 0;
}
.row > .article-content > .libfl_block {
  margin: 10px 20px 0 0;
}
a.switch-language {
  cursor: pointer;
  display: block;
  float: right;
  width: 24px;
  height: 24px;
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  margin-top: 13px;
  margin-left: 30px;
}
a.switch-language:hover {
  opacity: .8;
  transition: opacity .2s;
}
.special-anchors {
  margin-bottom: 70px;
}
.special-anchors a {
  display: inline-block;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 15px;
  font-weight: 700;
  color: #232325;
  padding: 14px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #f5f6f7;
  border-radius: 38px;
}
.special-anchors a:hover {
  background-color: #E9EDF2;
  transition: all .3s;
}
h2.special-header {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 1.2;
  color: #232325;
}
h2.special-header + .special-wrapper {
  margin-top: 40px;
}
.special-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -40px;
  margin-bottom: 80px;
}
.special-wrapper .hidden-anchor {
  position: absolute;
  top: -140px;
}
.special-block {
  position: relative;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 14px rgba(0, 0, 243, 0.11);
  margin-left: 40px;
  margin-bottom: 40px;
}
.special-block:hover {
  box-shadow: 0 2px 24px rgba(0, 0, 243, 0.11);
  transition: all .3s;
}
.special-block:hover img {
  filter: saturate(1.3);
  transition: all .3s;
}
.special-block:last-child {
  margin-right: auto;
}
.special-block .special-block-link {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}
.special-block img {
  display: block;
  width: 100%;
}
.special-block .special-block-title {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #232325;
  padding: 30px;
}
.special-block.triple {
  width: 100%;
  height: auto;
  border-radius: 0;
}
.special-block.double {
  width: calc(100% / 3 * 2 - 40px);
  height: auto;
  border-radius: 0;
}
.special-block.single {
  width: calc(100% / 3 - 40px);
  height: auto;
}
@media screen and (max-width: 1100px) {
  h2.special-header + .special-wrapper {
    margin-top: 30px;
  }
  .special-wrapper {
    margin-left: -30px;
    margin-bottom: 40px;
  }
  .special-block {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  .special-block.triple {
    height: auto;
  }
  .special-block.double {
    width: 100%;
    height: auto;
  }
  .special-block.single {
    width: calc(100% / 2 - 30px);
    height: auto;
  }
  .special-block.single + .special-block.double {
    order: -1;
  }
}
@media screen and (max-width: 768px) {
  h2.special-header + .special-wrapper {
    margin-top: 20px;
  }
  .special-wrapper {
    margin-left: 0;
    margin-bottom: 30px;
  }
  .special-block {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .special-block.single {
    width: 100%;
    height: auto;
  }
}
/* LINKS */
a,
.link {
  cursor: pointer;
  text-decoration: none;
}
/*a:visited {
	color: inherit;
}*/
.link_grey {
  font-size: 12px;
}
.link_blue {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  color: #003DF3;
  font-size: 14px;
  font-weight: bold;
  line-height: 1em;
}
.link_black {
  color: #232325;
}
/**/
p {
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 45px;
}
/*#layout_wrapper {
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	background-color: @white;
}*/
.content-wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  /*margin-bottom: -125px;
	padding-bottom: 125px;*/
}
.right {
  float: right;
}
.left {
  float: left;
}
.clear {
  clear: both;
}
/* BUTTONS */
.button {
  cursor: pointer;
  position: relative;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 3px;
  padding: 10px;
}
a.button {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #003DF3;
}
.button--plain-with-border {
  border: 1px solid #E5E5E5;
}
.button__label {
  margin: 35px 0 20px;
  background: #FFFFFF;
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.15);
}
.button:hover {
  transition: 200ms ease;
  transform: translateY(1px);
}
.button__label:hover {
  color: #7900f3;
  box-shadow: 0 5px 30px rgba(131, 0, 243, 0.15);
  transition: .2s;
}
.button--with-shadow {
  transition: 0.5s box-shadow;
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.15);
}
.button--with-shadow:hover {
  box-shadow: 0 6px 24px rgba(42, 42, 243, 0.15);
}
/* NAVBAR */
.events-filters__wrapper,
.events-filters {
  height: 58px;
  background-color: #FFFFFF;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}
.events-filters__item,
.events-filters__switch {
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 58px;
}
.events-filters__item {
  color: #232325;
}
.events-filters__switch {
  display: none;
  cursor: pointer;
  color: #003DF3;
  float: right;
}
.events-filters__switch span {
  width: 8px;
  height: 5px;
  background: url(../images/svg/caron-icon.svg) 0 0;
  transform: rotate(0deg);
  background-size: cover;
  margin-top: -1px;
  right: 0;
}
.events-filters__checkbox {
  float: right;
}
.events_date_btn {
  float: left;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 58px;
  position: relative;
  color: #232325;
  margin-right: 40px;
}
.events_cal_btn {
  float: left;
  background: transparent url('../images/svg/calendar.svg') no-repeat left 16px;
  background-size: 20px;
  padding-left: 30px;
}
.events_cal_btn.active input {
  width: 100px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 58px;
  margin-top: 2px;
  border-bottom: 0;
}
.events_cal_btn.active input,
.events_cal_btn.active .clear_events_date {
  display: block;
}
.events_cal_btn.active span,
.events_cal_btn input,
.events_cal_btn .clear_events_date {
  display: none;
}
.clear_events_date {
  width: 40px;
  height: 40px;
  background: url(../images/svg/close-black.svg) center no-repeat;
  -webkit-mask: url(../images/svg/close-black.svg) center no-repeat;
  mask: url(../images/svg/close-black.svg) center no-repeat;
  background-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: none;
}
.clear_events_date svg > path {
  fill: black;
}
.clear_events_date:hover {
  opacity: 0.8;
}
#current_cycle {
  background: #000;
  color: #FFFFFF;
  margin-top: 25px;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 11px;
  padding-right: 40px;
  display: none;
  position: relative;
}
#current_cycle.active {
  display: inline-block;
}
.remove_cycle {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 6px;
  width: 15px;
  height: 15px;
}
.remove_cycle:before,
.remove_cycle:after {
  content: ' ';
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #003DF3;
}
.remove_cycle:before {
  transform: rotate(-45deg);
}
.remove_cycle:after {
  transform: rotate(45deg);
}
.navbar-item__datepicker {
  cursor: pointer;
}
.navbar-item__datepicker:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 58px;
  margin-right: 8px;
  background: url(../images/svg/calendar.svg) center no-repeat;
  background-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
}
#events-date-start {
  font-size: 14px;
  font-weight: bold;
  color: #232325;
  margin-top: 4px;
}
.navbar-item__select {
  width: auto;
}
/* EVENT DATE */
.event-date {
  position: relative;
  margin-top: 42px;
  margin-bottom: 30px;
  line-height: 1;
}
.event-date:before {
  content: '';
  position: absolute;
  width: 35px;
  height: 4px;
  background-color: #003DF3;
  top: -15px;
  left: 0;
}
.event-date__day-of-the-month {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}
small.event-date_year {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 12px;
  font-weight: bold;
}
.event-date_weekday {
  font-size: 12px;
  font-weight: 300;
  color: #B4BAC1;
  text-transform: lowercase;
}
/* HEADER */
/*.header {
	position: relative;
	height: 210px;
	margin: 30px 0 10px;
}

.header__wisemen_block {

}
.quick-links {
  margin-left: 100px;
  margin-top: 35px;
  float: left;
}
a.quick-links__item {
	font-family: 'Open Sans', Arial, Helvetica;
	font-size: 13px;
	font-weight: 600;
	line-height: 28px;
	color: @color-main;
}

a.closing-time {
  font-family: 'Open Sans', Arial, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
  color: @gray;

  &:after {
	  content: "";
	  display: inline-block;
	  width: 15px;
	  height: 15px;
	  background: @gray--light url(/images/svg/arrow-back.svg) 50% no-repeat;
	  background-size: 10px;
	  border-radius: 4px;
	  transform: rotate(180deg);
	  margin-bottom: -3px;
	  margin-left: 5px;
  }
  &:hover,:focus {
	color: #a8a8a8;
	transition: color .2s;
  }
}
*/
/* MENU */
.footer__menu {
  float: left;
}
.menu__item {
  line-height: 50px;
  list-style-type: none;
}
a.menu__link {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #232325;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
a.menu__link:hover {
  color: #898D91;
}
a.menu__link_footer {
  font-size: 14px;
  color: #B4BAC1;
}
a.menu__link_footer:hover {
  color: #bebebe;
}
.menu__item_active {
  border-bottom: 1px solid #003DF3;
}
.header__menu .menu__item {
  float: left;
  margin-right: 30px;
  height: 50px;
}
.footer__menu .menu__link {
  color: #FFFFFF;
}
.header__menu .link_blue {
  float: right;
}
.footer__menu .menu__item {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 36px;
}
/* SEARCH */
.header__search-form .search-form {
  z-index: 10;
  position: relative;
  float: right;
}
.search-form__input {
  height: 100%;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  box-shadow: none;
}
/* EVENT BLOCK */
.events-wrapper {
  position: relative;
  margin: 0 auto;
}
/*.events-item,
.events-item_background {
	position: relative;
	float: left;
	height: 384px;
	width: 384px;
	padding: 30px 30px 0;
	margin-right: 23px;
	margin-bottom: 23px;
	background: @white;
	border-radius: 5px;
	box-shadow: 0px 2px 14px 0 rgba(0, 0, 243, 0.11);
}
.events-item:nth-child(3),
.events-item_background:nth-child(3) {
	margin-right: 0;
}*/
.events-item_background {
  padding: 0;
}
.events-item__image {
  height: 100%;
  width: 100%;
  /*background-color: rgba(0,0,0,.5);*/
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  position: relative;
  display: block;
}
.events-item__bottom {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 25px;
}
.events-item .event-type {
  margin-bottom: 20px;
}
.events-item_background .event-type {
  margin-bottom: 10px;
}
.events-item_background .event-type a {
  font-weight: 300;
  color: #FFFFFF;
}
.events-item_background .event-type a:hover,
.events-item_background .event-type a :focus {
  color: #FFFFFF;
  opacity: .9;
  transition: opacity .2s;
}
.news_about .event-tag {
  position: absolute;
  top: 204px;
  left: 24px;
  right: auto;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news_about .centenary_tag,
.news_about .donbass_tag {
  z-index: 3;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #003DF3;
  border-radius: 2px;
  padding: 1px 8px 2px;
}
.events-item .event-tag,
.events-item_background .event-tag {
  position: absolute;
  top: 28px;
  right: -4px;
}
.events-item__header {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  color: #232325;
}
.events-item_background .event-type,
.events-item_background .events-item__header,
.events-item_background .events-item__place,
.events-item_background .events-item__time {
  color: #FFFFFF;
}
.events-item_background .events-item__time {
  margin-top: 10px;
}
.events-item_background .events-item__place {
  margin-top: 20px;
}
.events-item_background .events-item__place:after {
  background: url(../images/svg/event-place-white.svg) center no-repeat;
}
.events-item_background .events-item__bottom {
  bottom: 25px;
}
.events-item__lead {
  padding: 10px 0 0;
  max-height: 140px;
  overflow: hidden;
}
.events-item__lead-text,
.events-item__lead-text p {
  font-size: 15px;
  line-height: 22px;
  font-weight: normal;
  max-height: 110px;
}
.events__item .event-type {
  padding-bottom: 13px;
}
.events-item__place {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: #B4BAC1;
  margin-bottom: 8px;
}
.events-item__place:after {
  content: '';
  display: inline-block;
  width: 11px;
  height: 13px;
  margin-left: 10px;
  background: url(../images/svg/event-place-grey.svg) center no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-top: -2px;
}
.online-event.events-item__place:after {
  content: none;
}
.events-item__time {
  font-size: 24px;
  font-weight: 400;
  color: #232325;
}
.page-views:after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  background: url(../images/svg/eye-grey.svg) center no-repeat;
  background-size: 14px;
  vertical-align: middle;
  margin-top: -2px;
}
.event-flag {
  position: absolute;
  width: 31px;
  height: 31px;
  border-radius: 11px;
  bottom: 30px;
  right: 30px;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 243, 0.15);
}
.event-card {
  position: absolute;
  width: 100px;
  height: 31px;
  border-radius: 11px;
  bottom: 30px;
  right: 30px;
}
.news_block .event-flag {
  bottom: -16px;
}
/* LONG TERM EVENTS BLOCK */
.long-term-events {
  position: relative;
  background-color: #f5f6f7;
  border-radius: 5px;
  padding: 30px 30px 0;
  height: 384px;
  width: 384px;
  overflow: hidden;
}
.long-term-events__inner {
  /*width: 100%;
	height: 99999px;*/
  transition: all .3s ease;
}
.long-term-events__title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #aeb5bc;
  text-transform: uppercase;
}
.long-term-events__arrows {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding: 20px 20px 20px 0;
  background: linear-gradient(180deg, rgba(245, 245, 245, 0.1) 0, #f4f7fb);
}
.long-term-events__arrow {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 0 2px;
}
.long-term-events__arrow_bg {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.long-term-events__arrow_up,
.long-term-events__arrow_down {
  position: absolute;
  width: 32px;
  height: 32px;
  background: url(../images/svg/arrow.svg) no-repeat center;
  background-size: 13px;
}
.long-term-events__arrow_down {
  transform: rotate(180deg);
}
.long-term-events__item {
  padding: 18px 0 14px;
  border-bottom: 1px solid #E5E5E5;
}
.long-term-events__item:last-child {
  border-bottom: none;
  padding: 18px 0 100px;
}
a.long-term-events__item-header {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: #232325;
  text-transform: none;
}
.long-term-events__item .event-type {
  padding-bottom: 13px;
}
/* EVENT */
.event-label {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background: #FA8905;
  padding: 6px 13px;
}
.event-label__text {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
}
.event-content {
  position: relative;
  padding: 0;
}
.event-image,
.new-image {
  position: relative;
  float: left;
}
.event-image__image-source {
  width: 890px;
  height: 472px;
  background-size: cover;
  background-position: center;
}
.new-image__image-source {
  width: 690px;
  height: 366px;
  background-size: cover;
  background-position: center;
}
.event-image__image-description,
.new-image__image-description {
  width: 100%;
  max-width: 890px;
  font-size: 14px;
  font-weight: 400;
  color: #6B7274;
  padding: 15px 0 0;
}
.event-info,
.new-info {
  z-index: 2;
  position: absolute;
  right: 0;
  background: #FFFFFF;
  padding: 30px 0 45px 40px;
  float: right;
}
.event-info {
  top: 45px;
  width: 383px;
  height: 383px;
}
.new-info {
  top: 33px;
  width: 590px;
  height: 300px;
}
.event-tag,
.event-cycle {
  display: inline-block;
  padding: 1px 8px 2px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  border-radius: 2px;
}
.event-tag {
  background: #FA8905;
}
.event-cycle {
  background: #003DF3;
}
.event-image .event-tag,
.event-image .event-cycle {
  position: absolute;
  top: 20px;
  left: 20px;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*.event-info .event-tag {
	position: absolute;
	right: -6px;
	top: 27px;
}*/
.events-item .event-cycle {
  margin-bottom: 10px;
}
.events-item_background .event-cycle {
  position: absolute;
  top: 30px;
  left: 30px;
}
.events_cat a,
.event-type a,
.new-type a,
.inner-event-type a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #003DF3;
}
.events_cat a:hover,
.event-type a:hover,
.new-type a:hover,
.inner-event-type a:hover {
  color: #7900f3;
  transition: color .2s;
}
.inner-event-type {
  margin-bottom: 25px;
  display: flex;
}
.inner-event-type a {
  color: #898D91;
}
.inner-event-type a:hover {
  color: #B4BAC1;
  transition: color .2s;
}
.inner-event-type span {
  font-size: 14px;
  font-weight: 600;
  color: #232325;
  margin-left: auto;
}
.event-info .event-type,
.event-info .inner-event-type,
.new-info .new-type {
  margin-bottom: 25px;
}
.event-info__title,
.new-info__title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0.3em;
}
.event-info__place {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.6;
}
/*.event-info__place:after {
	content: '';
	display: inline-block;
	width: 11px;
	height: 13px;
	margin-left: 10px;
	background: url('/images/svg/event-place-blue.svg') center no-repeat;
	background-size: cover;
	vertical-align: middle;
	margin-top: -2px;
}*/
.event-info__calendar {
  display: inline-block;
}
.event-info__shedule,
.new-info__shedule {
  position: absolute;
  width: 300px;
  bottom: 35px;
  line-height: 1.8;
}
.new-info__date {
  color: #B4BAC1;
  font-size: 14px;
  font-weight: 400;
  padding-top: 15px;
}
.event-info__date {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
}
.event-info__calendar:after {
  content: '';
  position: absolute;
  top: -2px;
  width: 26px;
  height: 26px;
  background: #FFFFFF url(../images/svg/three-dots.svg) center no-repeat;
  background-size: 13px;
  margin-left: 10px;
  border-radius: 25px;
  box-shadow: 0 0 8px 0 #cecefd;
}
.event-info__time {
  font-size: 24px;
  font-weight: 400;
  color: #232325;
}
.event-info__admission {
  position: absolute;
  width: 130px;
  top: 0;
  right: -40px;
  text-align: right;
}
.event-info__admission--fee {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 18px;
  color: #232325;
}
.event-info__admission--fee span {
  margin-right: 45px;
}
.event-info__admission--fee:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 34px;
  height: 21px;
  background: url(../images/svg/fee-icon.svg) no-repeat center;
  background-size: contain;
}
.event-info__admission--docs {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: lowercase;
  color: #B4BAC1;
}
.event-description {
  width: 800px;
  margin: 60px auto 0;
}
.event-description__text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
.inner-quote {
  position: relative;
  padding: 20px 0 30px 140px;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.13;
}
.inner-quote__author {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 16px;
  font-weight: 300;
  color: #B4BAC1;
  text-align: right;
  padding-top: 30px;
}
.inner-quote:before {
  content: ' ';
  position: absolute;
  background-image: url(../images/svg/quote-icon.svg);
  background-size: cover;
  width: 60px;
  height: 38px;
  margin-top: -10px;
  margin-left: -140px;
}
/* BUTTONS */
button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button--registration {
  color: #FFFFFF;
  background: #003DF3;
}
/* ARTICLE */
.article-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
}
.article-description {
  flex: 1;
  margin-left: 0;
  min-width: 500px;
  max-width: 880px;
}
.article-description.full_width {
  width: 100%;
  float: none;
}
.article-nav-childs__header {
  background-color: #232325;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1rem;
}
.article-nav-childs {
  width: 282px;
  background-color: #f5f6f7;
  font-size: 15px;
  margin-left: 30px;
}
.article-nav-childs a {
  display: block;
  padding: 1rem;
  color: #232325;
}
.article-nav-childs a:hover {
  background-color: #d9dde0;
}
@media screen and (max-width: 900px) {
  .article-nav-childs .article-nav-childs__header {
    position: relative;
  }
  .article-nav-childs .article-nav-childs__header:after {
    content: "";
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    display: block;
    width: 20px;
    height: 20px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-image: url(../images/svg/plus.svg);
    background-color: #FFFFFF;
  }
  .article-nav-childs a {
    display: none;
  }
  .article-nav-childs.active .article-nav-childs__header:after {
    -webkit-mask-image: url(../images/svg/minus.svg);
  }
  .article-nav-childs.active a {
    display: block;
  }
}
.article-description__header {
  /*font-family: @font-family--secondary;
	font-size: 36px;
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 50px;*/
}
/*.article-description {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		padding-left: 1em;
		font-size: 16px;
		font-weight: 200;
	}
	ul li {
		padding: 0;
		margin-bottom: 10px;
		line-height: 1.8;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		padding-left: 1.2em;
		color: #4A4A4A;
	}
	ul>li:before {
		background: @color-main;
		content: " ";
		float: left;
		margin-left: -2em;
		display: block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-top: .7em;
		margin-right: 0;
		padding-left: 0;
	}
}
*/
.article-description__text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}
/* DEPARTMENT */
.department-content {
  position: relative;
  padding: 0;
}
.department-description {
  width: 880px;
  margin-left: 0;
}
.department-description__header {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 50px;
}
.department-description__text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
/* BREADCRUMBS */
.breadcrumbs__line {
  width: 100%;
  padding: 20px 0 15px;
}
a.breadcrumbs__parent {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #003DF3;
}
.breadcrumbs__parent:before {
  content: ' ';
  display: block;
  float: left;
  background: url(../images/svg/caron-icon.svg) left no-repeat;
  width: 13px;
  height: 13px;
  margin-top: 6px;
  margin-right: 10px;
  background-size: contain;
  transform: rotate(90deg);
}
/* DEPARTMENT MENU */
.department__menu {
  position: relative;
  background-color: #FFFFFF;
  padding: 5px 0;
  margin: 50px 0;
}
.department__menu img {
  position: absolute;
  top: -75px;
  right: -300px;
  width: 78px;
  height: 78px;
  border-radius: 11px;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 243, 0.15);
}
.department__menu-item {
  display: none;
}
.department__menu-anchor {
  position: relative;
}
.department__menu-anchor:before {
  display: block;
  content: '';
  position: absolute;
  top: -60px;
}
.department__menu_left {
  float: left;
}
.department__menu_right {
  float: right;
}
.department__menu a {
  font-size: 14px;
  font-weight: 600;
  color: #003DF3;
  text-decoration: none;
  padding: 6px 10px;
  border: 1px solid #E5E5E5;
  border-radius: 3px;
  margin-right: 3px;
}
.department__events_btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #003DF3;
  background: #FFFFFF;
  padding: 10px 12px;
  border-radius: 3px;
  margin-top: -10px;
  box-shadow: 0 6px 24px 0 rgba(42, 42, 243, 0.15);
}
.department__menu .department__events_btn {
  margin-right: 60px;
}
.department__events_btn:before {
  content: "";
  float: left;
  width: 14px;
  height: 16px;
  margin-top: 1px;
  margin-right: 10px;
  background: url(../images/svg/calendar-blue.svg) no-repeat center;
}
/*.department__menu a.active,
.inner_navigation_switcher a.active {
	color: @white;
	background-color: @color-main;
	opacity: 1 !important;
	cursor: default;
	padding: 14px 14px 10px 18px;
	margin: -1px 0 0;
	border-bottom-color: @color-main;
}
.inner_navigation_switcher a:first-child {
	margin-top: -5px;
}
.inner_navigation_switcher a.active:last-child {
	margin-bottom: -5px;
}
.inner_navigation_switcher a:last-child {
	border-bottom: none;
}*/
/* SOCIAL MEDIA */
.collection_description .page-views {
  position: relative;
  bottom: 0;
}
.page-views {
  position: absolute;
  bottom: 62px;
  right: 0;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: #b8bbbf;
}
/* CHECKBOX */
.icheckbox_minimal {
  border: 2px solid #232325;
  border-radius: 2px;
  width: 19px;
  height: 19px;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-right: 10px;
}
.icheckbox_minimal.checked {
  background: #FFFFFF url(../images/svg/checkbox-icon.svg) no-repeat center;
  background-size: 12px;
}
svg.icheckbox_minimal.checked {
  fill: #003DF3;
}
.icheckbox_minimal input {
  opacity: 0 !important;
}
.events_filters {
  height: 61px;
  border-bottom: 1px solid #d5dde3;
  padding-bottom: 20px;
  padding-top: 19px;
}
/*.events-filters__select {
	font-size: 21px;
}*/
.events_filters label.checkbox {
  top: -1px;
  position: relative;
}
label.checkbox {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-left: 30px;
  display: block;
  float: left;
}
label.checkbox .icheckbox_minimal {
  margin-right: 15px;
}
/*
.banner-wrapper {
	width: 100% !important;
	text-align: center;
}
.top-banner {
	display: none;
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 25px;
}
.top-banner.active {
	display: block;
}
.top-banner {
	font-size: 16px;
	color: @gray--darken;
	text-align: center;
	padding: 25px 70px 25px 25px;
	background-color: #F8EE6E;
	line-height: 21px;
	margin-bottom: 0;
	font-weight: 700;
	border-radius: 4px;
}
.top-banner a {
	color: @gray--darken;
}
.top-banner__close {
	cursor: pointer;
	position: absolute;
	width: 67px;
	height: 100%;
	top: 0;
	right: 0;
	background: url(/images/svg/close-sand.svg) center no-repeat;
	background-size: 19px;
}

.top-banner__close:hover {
	opacity: .9;
}
*/
.event_organizer a:hover,
a.event-type:hover,
.event-type a:hover,
.link_blue:hover,
a.quick-links__item:hover,
a.breadcrumbs__parent:hover {
  color: #7900f3;
  transition: color .2s;
}
.test-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  grid-gap: 23px;
}
.events-item_background,
.events-item,
.long-term-events {
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  position: relative;
  /*float: left;*/
  height: 384px;
  padding: 30px 30px 0;
  /*width: 384px;
	padding: 30px 30px 0;
	margin: 11px;
	background: @white;*/
  border-radius: 5px;
}
.events-item_background {
  padding: 0 !important;
}
.events-item_background:hover,
.events-item_background :focus {
  filter: saturate(1.8);
}
.events-item {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 243, 0.11);
}
.catalogue-search_background {
  position: relative;
  height: 430px;
  padding: 6% 26% 0;
  background: rgba(5, 5, 138, 0.54) url(../images/catalogue.jpg) center / cover;
  background-blend-mode: multiply;
  text-align: center;
}
.catalogue-search_title {
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 1.3;
  color: #FFFFFF;
  text-transform: uppercase;
}
.catalogue-search_title span {
  font-size: 20px;
}
.button__filled {
  font-size: 20px !important;
  font-weight: bold;
  color: #FFFFFF !important;
  padding: 20px 50px;
  margin: 35px 0 20px;
  background: #003DF3;
  border-radius: 25px;
}
.catalogue-item {
  position: relative;
  width: 50%;
  max-width: 584px;
  height: 420px;
  padding: 50px;
  margin: 35px 0 60px;
  background: #ECF1F5;
}
.catalogue-item_title {
  padding: 0 0 30px;
}
.catalogue-item_title {
  display: block;
  font-family: 'Leto Sans', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.3;
  color: #232325;
  text-transform: uppercase;
}
.catalogue-item_links a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #003DF3;
  line-height: 2.2;
}
.catalogue-item_links a:hover {
  color: #7900f3;
  transition: color .2s;
}
.catalogue-item_resourses .catalogue-item_links a {
  color: #FFFFFF;
}
.catalogue-item_resourses .catalogue-item_links a:hover {
  color: #E5E5E5;
}
.catalogue-item.catalogue-item_collections {
  float: left;
}
.catalogue-item.catalogue-item_resourses {
  float: right;
  background: url(../images/online-resourses.jpg) center no-repeat;
}
.catalogue-item.catalogue-item_resourses .catalogue-item_title {
  color: #FFFFFF;
}
h4.catalogue_title {
  text-align: center;
}
.catalogue-item_collections-books {
  position: absolute;
  max-width: 330px;
  width: 50%;
  bottom: 80px;
  right: 0;
  text-align: right;
}
.catalogue-item_collections-books img {
  width: 33%;
  padding-right: 6%;
}
.section-wrapper {
  margin-bottom: 100px;
}
.section-heading {
  color: #0E0E0F;
  font-family: 'Leto Sans', sans-serif, Arial, Helvetica;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  padding: 1rem 0 2rem;
  text-transform: uppercase;
}
.todo-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .todo-list {
    margin-left: 0;
  }
}
.todo-list-item {
  border-radius: 9px;
  height: 190px;
  margin-bottom: 30px;
  margin-left: 30px;
  padding: 30px 35px;
  position: relative;
  width: calc(100% / 3 - 30px);
}
@media screen and (max-width: 900px) {
  .todo-list-item {
    width: calc(100% / 2 - 30px);
  }
}
@media screen and (max-width: 768px) {
  .todo-list-item {
    margin-bottom: 10px;
    margin-left: 0;
    width: 100%;
  }
}
.todo-list-item__link {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.todo-list-item__title {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  width: min-content;
}
