@import 'variables';

@keyframes scaleInOut {
  0%   { transform: scale(1, 1); }
  50%   { transform: scale(1.15, 1.15); }
  100%   { transform: scale(1, 1); }
}

@-webkit-keyframes scaleInOut {
  0%   { -webkit-transform: scale(1, 1); }
  50%  { -webkit-transform: scale(1.15, 1.15); }
  100%  { -webkit-transform: scale(1, 1); }
}

.index-sources {
  margin-bottom: 80px;
}

.index-sources__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 0 30px;
  margin: 0;
  list-style: none;
  border-top: 1px solid @gray--light;
  border-bottom: 1px solid @gray--light;
}

.index-sources__item {
  width: ~"calc(100% / 4 - 20px)";
  margin-top: 15px;

  @media screen and (max-width: 1100px) {
    width: ~"calc(100% / 2 - 20px)";
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.index-sources__link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.36;
  color: @gray--darken;

  &:hover {
    color: @gray--dark;
    transition: all .3s;

    .index-sources__image {
      animation: scaleInOut 1s;
      -webkit-animation: scaleInOut 1s;
    }
  }
}

.index-sources__image {
  margin-right: 20px;
}